import { Component, Input, OnInit } from '@angular/core';
import { distinct, take } from 'rxjs/operators';
import {
  MatDialog
} from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { PlayerDrill } from '../models/playerDrill';
import { FirebaseService } from '../shared/firebase.service';
import { NotificationService } from '../shared/notification.service';


@Component({
  selector: 'app-drill-category-list',
  templateUrl: './drill-category-list.component.html',
  styleUrls: ['./drill-category-list.component.css'],
})
export class DrillCategoryListComponent implements OnInit {
  // @Input() private category: string;
  @Input() private playerId: string;
  @Input() private teamId: string;
  @Input() private isTeamList: boolean;
  @Input() private editable: boolean;

  private categoryIcon: string;
  private drillCategory: string;

  private drillList: PlayerDrill[] = [];

  public drillListFull = false;
  public isLoadingResults: boolean;

  constructor(
    private firebase: FirebaseService,
    public dialog: MatDialog,
    private notification: NotificationService
    ) {}

  ngOnInit() {
    this.isLoadingResults = true;
    /* console.log();
    switch (this.category) {
      case 'Físicas':
        this.categoryIcon = '../assets/images/evaluationPhysicalOutline.svg';
        this.drillCategory = 'PH';
        break;
      case 'Mentalidad':
        this.categoryIcon = '../assets/images/evaluationMentalOutline.svg';
        this.drillCategory = 'BE';
        break;
      case 'Táctica':
        this.categoryIcon = '../assets/images/evaluationTacticsOutline.svg';
        this.drillCategory = 'TA';
        break;
      case 'Técnica':
        this.categoryIcon = '../assets/images/evaluationTechniqueOutline.svg';
        this.drillCategory = 'TE';
        break;
    } */

    // getPlayerDrillsByDrillCategory(playerId: string, drillCategory: string)
    if (this.isTeamList) {
      this.firebase
      .getPlayerDrillsWhereTeamId(
        this.teamId
      )
      .subscribe((tDrill) => {
        // console.log(tDrill.length);
        tDrill.forEach((tD) => {
          this.drillList.push(tD as PlayerDrill);
        });
        this.firebase
          .getPlayerDrillsWhereAll()
          .pipe(take(1))
          .subscribe((aDrill) => {
            // console.log(aDrill.length);
            aDrill.forEach((aD) => {
            this.drillList.push(aD as PlayerDrill);
          });
          // sort drills by name
          this.drillList.sort(function(drillA, drillB) {
            return drillA.drillName.localeCompare(drillB.drillName);
          });

          this.drillListFull = true;
          this.isLoadingResults = false;
          });
      });

    } else {
      this.firebase
      .getPlayerDrillsWherePlayerId(
        this.playerId
      )
      .pipe(take(1))
      .subscribe((pDrill) => {
        // console.log(pDrill.length);
        pDrill.forEach((pD) => {
          this.drillList.push(pD as PlayerDrill);
        });
        this.firebase
          .getPlayerDrillsWhereTeamId(
            this.teamId
          )
          .subscribe((tDrill) => {
            // console.log(tDrill.length);
            tDrill.forEach((tD) => {
              this.drillList.push(tD as PlayerDrill);
            });
            this.firebase
              .getPlayerDrillsWhereAll()
              .pipe(take(1))
              .subscribe((aDrill) => {
                // console.log(aDrill.length);
                aDrill.forEach((aD) => {
                  this.drillList.push(aD as PlayerDrill);
                });

                // sort drills by name
                this.drillList.sort(function(drillA, drillB) {
                  return drillA.drillName.localeCompare(drillB.drillName);
                });
                this.drillListFull = true;
                this.isLoadingResults = false;
              });
          });
        });

    }

  }

  onDeleteDrill(drill) {
    // console.log(drill);
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      // width: '30%',
      disableClose: true,
      autoFocus: true,
      data: { action: 'delete drill', object: drill },
    });
    dialogRef.addPanelClass('dialogWidth');
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.firebase.deletePlayerDrill(drill.playerDrillId);
        this.drillList = [];
        this.notification.success('Entrenamiento Eliminado');
      }
    });
  }

  getCategoryIcon(drillCategory: string): string {
    let icon: string;
    switch (drillCategory) {
      case 'PH':
        icon = '../assets/images/evaluationPhysicalOutline.svg';
        // this.drillCategory = 'PH';
        break;
      case 'BE':
        icon = '../assets/images/evaluationMentalOutline.svg';
        // this.drillCategory = 'BE';
        break;
      case 'TA':
        icon = '../assets/images/evaluationTacticsOutline.svg';
        // this.drillCategory = 'TA';
        break;
      case 'TE':
        icon = '../assets/images/evaluationTechniqueOutline.svg';
        // this.drillCategory = 'TE';
        break;
    }
    return icon;
  }

  getCategoryColor(drillCategory: string): string {
    let color: string;
    switch (drillCategory) {
      case 'PH':
        color = '#00ff87';
        // this.drillCategory = 'PH';
        break;
      case 'BE':
        color = '#e74cf5';
        // this.drillCategory = 'BE';
        break;
      case 'TA':
        color = '#ff015b';
        // this.drillCategory = 'TA';
        break;
      case 'TE':
        color = '#05f1ff';
        // this.drillCategory = 'TE';
        break;
    }
    return color;
  }

  isDeletableDrill(playerId: string): boolean {
    if (playerId === 'all') {
      return false;
    }
    return true;
  }

}
