import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent  {
  public email: string;
  public password: string;
  public confirmPassword: string;
  public hidePassword: boolean;
  public hideConfirmPassword: boolean;

  constructor(
    public authService: AuthService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['']);
    }

    this.matIconRegistry.addSvgIcon(
      'cantera_logo_full',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/images/canteraLogo_full_black.svg'
      )
    );

    this.hidePassword = true;
    this.hideConfirmPassword = true;
  }

  

  get navLogo() {
    return environment.versions.navLogo;
  }
}
