import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { FirebaseService } from '../shared/firebase.service';
import { InvitationService } from '../shared/invitation.service';

@Component({
  selector: 'app-sign-up-profile',
  templateUrl: './sign-up-profile.component.html',
  styleUrls: ['./sign-up-profile.component.css'],
})
export class SignUpProfileComponent implements OnInit {
  public firstName: string;
  public lastName: string;
  public type: string;
  public preferredPosition: string;
  public strongerSide: string;
  public level: string;
  public club: string;
  public userId: string;
  public email: string;
  public language: string;
  public profilePicturePath: string;
  public step: number;
  public player: string;
  public code: string;
  public birthday?: Date;
  public showParentOption?: boolean;
  public showJoinTeamOption?: boolean;
  public emailSubscription: boolean;

  constructor(
    public authService: AuthService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private firebaseService: FirebaseService,
    private invitation: InvitationService,
    private domSanitizer: DomSanitizer
  ) {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['']);
    }

    this.matIconRegistry.addSvgIcon(
      'cantera_logo_full',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.navLogo)
    );
  }

  ngOnInit() {
    this.step = 1;
    this.emailSubscription = true;
    this.authService.afAuth.authState.subscribe((user) => {
      this.userId = user.uid;
      this.email = user.email;
      this.language = 'en';
      this.profilePicturePath = 'profilePictures/default.png';
    });
    this.showParentOption = environment.signUp.enableParentOption;
    if (!this.showParentOption) {
      this.type = 'PL';
    }
    this.showJoinTeamOption = environment.signUp.enableJoinTeamOption;
  }

  get isPlayer(): boolean {
    return this.type === 'PL';
  }

  get isParent(): boolean {
    return this.type === 'PA';
  }

  next() {
    if(this.isPlayer && !this.showJoinTeamOption) {
      this.finish();
      // this.step++;
    } else {
      this.step++;
    }
  }

  async finish() {
    try {
      if (this.isPlayer) {
        let team;
        if(this.showJoinTeamOption) {
          team = await this.firebaseService.getTeamByCode(this.code);
          if (!team) {
            alert('Error!' + ' Invalid code.');
            return;
          }
        } else {
          team = {
            name: 'default',
            teamId: 'default',
            year: ' '};
        }

        await this.firebaseService.addUserInfo(
          this.firstName,
          this.lastName,
          this.language,
          this.userId,
          this.profilePicturePath,
          this.email,
          this.emailSubscription
        );

        const invitationCode =
          await this.invitation.generatePlayerInvitationCode();
        await this.firebaseService.addPlayer(
          team.teamId,
          this.firstName,
          this.lastName,
          this.userId,
          this.preferredPosition,
          this.strongerSide,
          this.birthday ? this.birthday.getDay().toString() : '',
          this.birthday ? this.birthday.getMonth().toString() : '',
          this.birthday ? this.birthday.getFullYear().toString() : '',
          invitationCode,
          this.profilePicturePath,
          this.club,
          this.level
        );
        const player = await this.firebaseService.getPlayerByUserId2(
          this.userId
        );
        await this.firebaseService.addUserMap(
          team.name,
          team.teamId,
          this.userId,
          player.playerId,
          team.year.toString(),
          true,
          'Player'
        );
      } else {
        const player = await this.firebaseService.getPlayerByCode(this.code);
        if (!player) {
          alert('Error!' + ' Invalid code.');
          return;
        }
        await this.firebaseService.addUserInfo(
          this.firstName,
          this.lastName,
          this.language,
          this.userId,
          this.profilePicturePath,
          this.email,
          this.emailSubscription
        );
        await this.firebaseService.addSupported(player.playerId, this.userId);
        await this.firebaseService.addUserMap(
          '',
          '',
          this.userId,
          player.playerId,
          '',
          true,
          'Supporter'
        );
      }
      this.router.navigate(['home']);
    } catch (e) {
      alert('Error!' + e.message);
    }
  }

  get navLogo() {
    return environment.versions.navLogo;
  }
}
