<nav class="navbar navbar-dark bg-dark d-flex">
    <a class="mr-auto navbar-brand" href="#">
        <img [src]="navLogo" width="30" height="30" class="d-inline-block align-top" alt="">
    </a>
    <button i18n="@@reset-pass-sign-up-title" mat-stroked-button color="accent" class="py-2"
        (click)="goToSignUp()">Sign Up
    </button>
    <button i18n="@@login-title" mat-raised-button color="accent" class="py-2"
        (click)="goToLogin()">Log in
    </button>
</nav>
<div class="container main-div">
    <div class="row">
        <div class="col-md-6 mt-4">
            <!--<mat-icon svgIcon="cantera_logo_full" [inline]="true"></mat-icon>-->
            <img class="img-fluid" [src]="fullLogo">
        </div>
        <div class="col-md-6">
            <mat-card class="mat-elevation-z0">
                <mat-card-header>
                    <mat-card-title>
                    <h1 class="loginTitle" i18n="@@reset-reset-password-header">
                        Reset password
                    </h1>
                    </mat-card-title>
                </mat-card-header>
            <mat-card-content>
                <p class="pl-3" i18n="@@profile-security-reset-password-explanation">We will send you an email with a link to reset your password</p>
                <form>
                    <div class="row">
                        <div class="col-12">
                        <mat-form-field class="w-100" color="accent">
                            <input i18n-placeholder matInput placeholder="Email" [(ngModel)]="email" name="email" required (input)="onEmailChange($event.target.value)">
                        </mat-form-field>
                        </div>
                    </div>

                </form>
                <mat-spinner [style.display]="false ? 'block' : 'none'"></mat-spinner>
            </mat-card-content>
            <div class="row">
                <div class="col-12">
                    <button i18n="@@reset-pass-reset-password-button" mat-raised-button class="loginButtons" color="accent" (click)="resetPassword()" [disabled]="disableResetButton">Reset password</button>
                </div>
            </div>
            <div *ngIf="showResetPasswordResult">
                <ng-container *ngIf="resetPasswordResult">
                    <p class="small font-weight-light mt-2 pl-3" i18n="@@profile-security-reset-password-result-success">Email sent! Check your inbox and click on the link to reset your password.</p>
                </ng-container>
                <ng-container *ngIf="!resetPasswordResult">
                    <p class="small font-weight-light mt-2 pl-3" i18n="@@profile-security-reset-password-result-failure">Could not reset your password, contact support.</p>
                </ng-container>
            </div>
        </mat-card>
    </div>
    </div>
</div>
