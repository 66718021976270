<mat-toolbar color="accent">
  <span>{{ title }}</span>
  <span class="spacer"></span>
  <button
    class="btn-dialog-close"
    mat-stroked-button
    (click)="onClose()"
    tabIndex="-1"
  >
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>
<form [formGroup]="playerForm" class="normal-form">
  <mat-grid-list cols="2" rowHeight="24rem">
    <mat-grid-tile>
      <div class="controles-container">
        <mat-form-field color="accent">
          <input formControlName="firstName" matInput placeholder="Name" />
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>
        <mat-form-field color="accent">
          <input formControlName="lastName" matInput placeholder="Last Name" />
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>
        <mat-form-field color="accent">
          <input formControlName="teamId" matInput placeholder="Team ID" />
          <mat-error>This field is mandatory.</mat-error>
        </mat-form-field>
        <mat-form-field color="accent">
          <input
            formControlName="invitationCode"
            matInput
            placeholder="Invitation Code"
          />
        </mat-form-field>
        <mat-form-field color="accent">
          <input
            formControlName="club"
            matInput
            placeholder="club"
          />
        </mat-form-field>
        <mat-form-field color="accent">
          <input
            formControlName="level"
            matInput
            placeholder="Level"
          />
        </mat-form-field>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="controles-container">
        <div class="add-bottom-padding">
          <mat-radio-group formControlName="strongFoot">
            <mat-radio-button
              class="add-right-padding add-bottom-padding"
              value="1"
              >Right</mat-radio-button
            >
            <mat-radio-button
              class="add-right-padding add-bottom-padding"
              value="2"
              >Left</mat-radio-button
            >
            <mat-radio-button
              class="add-right-padding add-bottom-padding"
              value="3"
              >Both</mat-radio-button
            >
            <mat-radio-button
              class="add-right-padding add-bottom-padding"
              value="0"
              >None</mat-radio-button
            >
          </mat-radio-group>
        </div>
        <mat-form-field color="accent">
          <mat-select formControlName="position" placeholder="Position">
            <ng-container *ngFor="let position of positions">
              <mat-option [value]="position.posValue">{{
                position.posViewValue
              }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field color="accent">
          <input
            formControlName="birthDate"
            matInput
            [matDatepicker]="picker"
            [max]="maxDate"
            placeholder="Date of birth"
            disabled
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #picker
            disabled="false"
            [startAt]="startDate"
          ></mat-datepicker>
        </mat-form-field>
        <mat-form-field color="accent">
          <input formControlName="userId" matInput placeholder="User ID" />
        </mat-form-field>
        <mat-form-field color="accent">
          <input
            formControlName="profilePicturePath"
            matInput
            placeholder="Profile picture path"
          />
        </mat-form-field>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="button-row" mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="onClear()">Clear</button>
    <span class="spacer"></span>
    <button
      mat-raised-button
      color="accent"
      type="submit"
      [disabled]="playerForm.invalid"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </div>
</form>
