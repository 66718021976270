<nav class="navbar navbar-dark bg-dark">
    <a class="navbar-brand" href="#">
        <img [src]="navLogo" width="30" height="30" class="d-inline-block align-top" alt="">
    </a>
</nav>

<div class="container">
  <form #form="ngForm" class="normal-form py-4">
    <div *ngIf="step === 1">
      <div class="row">
        <h2 i18n="@@about-title">Tell us about yourself</h2>
      </div>
      <mat-grid-list cols="1">
        <div class="row">
          <mat-form-field color="primary" class="col-md-6 col-12">
            <input i18n-placeholder="@@first-name-title" matInput placeholder="First Name" [(ngModel)]="firstName"
              name="firstName" required />
            <mat-error i18n="@@required-title">This field is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field color="primary" class="col-md-6 col-12">
            <input i18n-placeholder="@@last-name-title" matInput placeholder="Last Name" [(ngModel)]="lastName"
              name="lastName" required />
            <mat-error i18n="@@required-title">This field is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <span class="lead" i18n="@@about-subscription-title">Notification preferences:</span>
          </div>
        </div>
        <div class="row my-2">
          <mat-checkbox color="primary" class="col-md-6 col-12" name="emailSubscription" [(ngModel)]="emailSubscription"  i18n="@@about-email-sub-toggle" >
            Subscribe to email notifications
          </mat-checkbox>
        </div>
        <div class="row my-2" *ngIf="showParentOption">
          <div class="col-md-6">
            <span i18n="@@about-type-title">You are a:</span>
          </div>
        </div>
        <div class="row mb-2" *ngIf="showParentOption">
          <mat-radio-group color="primary" class="col-md-6 col-12" [(ngModel)]="type" name="type" required>
            <mat-radio-button i18n="@@about-type-player-title" class="mr-3" value="PL">Player</mat-radio-button>
            <mat-radio-button i18n="@@about-type-parent-title" class="" value="PA">Parent</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="isPlayer">
          <div class="row mt-4 mb-2">
            <div>
              <h5 i18n="@@about-player-title">Player Information</h5>
            </div>
          </div>
          <div class="row">
            <mat-form-field color="primary" class="col-md-6 col-12">
              <input matInput i18n-placeholder="@@about-birthday-title" [(ngModel)]="birthday"
                placeholder="Birthday" [matDatepicker]="picker" name="birthday" required />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field color="primary" class="col-md-6 col-12">
              <mat-select i18n-placeholder="@@about-position-title" placeholder="Preferred Position"
                [(ngModel)]="preferredPosition" name="preferredPosition" required>
                <mat-option i18n="@@about-position-goalkeeper-title" value="GK'">
                  GK
                </mat-option>
                <mat-option i18n="@@about-position-centralback-title" value="CB">
                  CB
                </mat-option>
                <mat-option i18n="@@about-position-rb-title" value="RB">
                  RB
                </mat-option>
                <mat-option i18n="@@about-position-lb-title" value="LB">
                  LB
                </mat-option>
                <mat-option i18n="@@about-position-cdm-title" value="CDM">
                  CDM
                </mat-option>
                <mat-option i18n="@@about-position-cm-title" value="CM">
                  CM
                </mat-option>
                <mat-option i18n="@@about-position-rm-title" value="RM - RW">
                  RM / RW
                </mat-option>
                <mat-option i18n="@@about-position-lm-title" value="LM - LW">
                  LM / LW
                </mat-option>
                <mat-option i18n="@@about-position-fw-title" value="FW - ST">
                  FW / ST
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field color="primary" class="col-md-6 col-12">
              <mat-select i18n-placeholder="@@about-side-title" placeholder="Strong Side" [(ngModel)]="strongerSide"
                name="strongerSide" required>
                <mat-option i18n="@@about-side-left-title" value="Left">
                  Left
                </mat-option>
                <mat-option i18n="@@about-side-right-title" value="Right">
                  Right
                </mat-option>
                <!-- <mat-option i18n="@@about-side-both-title" value="Both">
                  Both
                </mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field color="primary" class="col-md-6 col-12">
              <input i18n-placeholder="@@club-title" matInput placeholder="Club" [(ngModel)]="club"
                name="club" />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field color="primary" class="col-md-6 col-12">
              <input i18n-placeholder="@@level-title" matInput placeholder="Team or level" [(ngModel)]="level"
                name="level" />
            </mat-form-field>
          </div>
        </div>
      </mat-grid-list>
      <div class="button-row mt-4" mat-dialog-actions>
        <button i18n="@@next-title" mat-raised-button color="accent" class="py-2" (click)="next()"
          [disabled]="form.invalid">
          Next
        </button>
      </div>
    </div>
    <div *ngIf="step === 2">
      <div *ngIf="isPlayer && showJoinTeamOption">
        <div class="row">
          <h2 i18n="@@about-join-team-title">Join a team</h2>
        </div>
        <div class="row">
          <span i18n="@@about-follow-player-subtitle">Use a code to be part of your team</span>
        </div>
        <mat-grid-list cols="1">
          <div class="row mt-4">
            <mat-form-field color="primary" class="col-md-6 col-12">
              <input i18n-placeholder="@@team-code-title" matInput placeholder="Team Code" [(ngModel)]="code"
                name="teamCode" required />
              <mat-error i18n="@@required-title">This field is required.</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-list>
      </div>
      <div *ngIf="isPlayer && !showJoinTeamOption">
        <div class="row">
          <h2>Click finish to start your journey</h2>
        </div>
      </div>
      <div *ngIf="isParent">
        <div class="row">
          <h2 i18n="@@about-follow-player-title">Follow a player</h2>
        </div>
        <div class="row">
          <span i18n="@@about-follow-player-parent-subtitle">Use the secure code to be able to see the player's information</span>
        </div>
        <mat-grid-list cols="1">
          <div class="row mt-4">
            <mat-form-field color="primary" class="col-md-6 col-12">
              <input i18n-placeholder="@@player-code-title" matInput placeholder="Player Code" [(ngModel)]="code"
                name="playerCode" required />
              <mat-error i18n="@@required-title">This field is required.</mat-error>
            </mat-form-field>
          </div>
        </mat-grid-list>
      </div>
      <div class="button-row mt-4" mat-dialog-actions>
        <button i18n="@@finish-title" mat-raised-button color="accent" class="py-2" (click)="finish()"
          [disabled]="form.invalid">
          Finish
        </button>
      </div>
    </div>
  </form>
  <mat-spinner [style.display]="false ? 'block' : 'none'"></mat-spinner>
</div>


