import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { Player } from '../models/player';
import { Supported } from '../models/supported';
import { User } from '../models/user';
import { FirebaseService } from '../shared/firebase.service';
import { InvitationService } from '../shared/invitation.service';
import { NotificationService } from '../shared/notification.service';
import { TeamDialogFormComponent } from '../teams-screen/team-dialog-form/team-dialog-form.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  private paginator: MatPaginator;
  private sort: MatSort;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  // private displayedColumns: string[] = ['name', 'teamId', 'year', 'actions'];
  private displayedColumnsCoach: string[] = ['name', 'year'];
  private displayedColumnsSupporter: string[] = ['fullName', 'position'];
  private displayedColumnsPlayer: string[] = ['fullName', 'position'];
  private dataSourceCoach = new MatTableDataSource();
  private dataSourceSupporter = new MatTableDataSource();
  private dataSourcePlayer = new MatTableDataSource();
  private initialSelection = [];
  private allowMultiSelect = false;
  private selection = new SelectionModel<any>(
    this.allowMultiSelect,
    this.initialSelection
  );

  public coachList: User[] = [];
  public supportedsList: Player[] = [];
  public playerList: Player[] = [];
  public userRole: string;
  private userId: string;
  private titleCoachTeams = 'Mis Equipos';
  private titleCoachPlayers = 'Mis Jugadores';
  private titleSupporter = 'Jugadores que sigo';
  private titlePlayer = 'Jugador';
  private noCoachTitle = 'No eres entrenador de ningún equipo';
  private noSupporterTitle = 'No sigues a ningún jugador';
  private noPlayerTitle = 'No eres jugador';
  private coachWithNoPlayers = 'No entrenas ningún jugador';
  public isLoadingResults = true;
  dialogConfig: MatDialogConfig;

  showCoachPlayers: boolean;
  showCoachTeams: boolean;

  constructor(
    private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    private notification: NotificationService,
    private invitation: InvitationService,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig .disableClose = true;
    this.dialogConfig .autoFocus = true;
  }

  doFilter(value: string) {
    this.dataSourceCoach.filter = value.trim().toLocaleLowerCase();
  }

  setDataSourceAttributes() {
    this.dataSourceCoach.paginator = this.paginator;
    this.dataSourceCoach.sort = this.sort;
    this.dataSourceSupporter.paginator = this.paginator;
    this.dataSourceSupporter.sort = this.sort;
    this.dataSourcePlayer.paginator = this.paginator;
    this.dataSourcePlayer.sort = this.sort;
  }

  async ngOnInit() {
    this.setLocalizedMessages();
    this.authService.afAuth.authState.subscribe(user => {
      this.isLoadingResults = true;
      this.userId = user.uid;
      this.firebase.getMappedTeams(this.userId).subscribe(data => {
        this.coachList.length = 0;
        this.playerList.length = 0;
        this.supportedsList.length = 0;
        data.forEach((u: User) => {
          this.userRole = u.role;
          switch (u.role) {
            case 'Coach':
              this.setCoachShownDataType();
              if (this.showCoachTeams) {
                this.coachList.push(u as User);
                this.isLoadingResults = false;
                break;
              } else {
                this.firebase.getPlayers()
                .pipe(take(1))
                .subscribe(playersData => {
                  if (this.playerList.length === 0) {
                    playersData.forEach((pl: Player) => {
                      pl.fullName = pl.firstName + ' ' + pl.lastName;
                      this.playerList.push(pl);
                    });
                  }

                  this.isLoadingResults = false;
                });
              }
              // this.coachList.push(u as User);
              // this.isLoadingResults = false;
              break;
            case 'Player':
              this.firebase
                .getPlayerByUserId(u.userId)
                .subscribe(playerUserData => {
                  playerUserData.forEach((playerUser: Player) => {
                    playerUser.fullName =
                      playerUser.firstName + ' ' + playerUser.lastName;
                    this.playerList.push(playerUser);
                  });
                  this.isLoadingResults = false;
                });
              break;
            case 'Supporter':
              this.firebase
                .getSupporteds(u.userId)
                .subscribe(supportedsData => {
                  if (supportedsData.length > 0) {
                    supportedsData.forEach((supported: Supported) => {
                      this.firebase
                        .getPlayer(supported.playerId)
                        .subscribe((player: Player) => {
                          player.playerId = supported.playerId;
                          player.fullName =
                            player.firstName + ' ' + player.lastName;
                          this.supportedsList.push(player);
                          this.isLoadingResults = false;
                        });
                    });
                  } else {
                    this.isLoadingResults = false;
                  }
                });
              break;
          }
        });
        if (environment.home.coach.show === 'teams') {
          this.dataSourceCoach = new MatTableDataSource(this.coachList);
          this.dataSourceCoach.sort = this.sort;
          this.dataSourceCoach.paginator = this.paginator;
        } else {
          this.dataSourceCoach = new MatTableDataSource(this.playerList);
          this.dataSourceCoach.sort = this.sort;
          this.dataSourceCoach.paginator = this.paginator;
          if(this.userRole === 'Coach') {
            if (environment.playerDashboard.showStats) {
              this.displayedColumnsPlayer.push('evaluation');
            }
            this.displayedColumnsPlayer.push('img');
          }
        }
        this.dataSourceSupporter = new MatTableDataSource(this.supportedsList);
        this.dataSourceSupporter.sort = this.sort;
        this.dataSourceSupporter.paginator = this.paginator;
        this.dataSourcePlayer = new MatTableDataSource(this.playerList);
        this.dataSourcePlayer.sort = this.sort;
        this.dataSourcePlayer.paginator = this.paginator;
        this.selection = new SelectionModel<User>(
          this.allowMultiSelect,
          this.initialSelection
        );
      });
    });
  }

  setCoachShownDataType() {
    if (environment.home.coach.show === 'teams') {
      this.showCoachPlayers = false;
      this.showCoachTeams = true;
    } else {
      this.showCoachPlayers = true;
      this.showCoachTeams = false;
    }
  }

  onEvaluate(id: string) {
    // this.dataSourceCoach.
    this.router.navigate(['/evaluation', id]);
  }

  setLocalizedMessages() {
    if (this.locale.includes('en')) {
      this.titleCoachTeams = 'My Teams';
      this.titleCoachPlayers = 'My Players';
      this.titleSupporter = 'Athletes I follow';
      this.titlePlayer = 'Athlete';
      this.noCoachTitle = 'You do not coach any team';
      this.coachWithNoPlayers = 'You do not coach any player';
      this.noSupporterTitle = 'You do not follow any player';
      this.noPlayerTitle = 'You do not have a player profile';
    }
  }

  navigateToTeamDasboard(row) {
    // console.log('pre-navigate');
    this.router.navigate(['team-dashboard']);
  }

  async createTeam() {
    const invitationCode = await this.invitation.generateInvitationCode();
    this.dialogConfig.width = '40%';
    this.dialogConfig.data = {
      callingFrom: 'header',
      disabledFields: [ 'invitationCode' ],
      defaultValueList: [
        { field: 'skillListId', value: environment.defaultSkillListId },
        { field: 'invitationCode', value: invitationCode },
        { field: 'userId', value: this.userId },
        { field: 'teamColor', value: '#FD2A5C' },
        { field: 'premium', value: true },
        { field: 'teamVideoCover', value: environment.defaultVideoCover }
      ]
    };
    const dialogRef = this.dialog.open(TeamDialogFormComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.teamLogo === '') { result.teamLogo = 'teams/logos/default.png'; }
        this.firebase.addTeamInvitationCode(result.name, result.year, result.userId,
                                                    result.skillListId, invitationCode,
                                                    result.teamColor, result.teamLogo, result.premium,
                                                    result.teamVideoCover, result.membershipMonthCutOffDay)
          .then(
            r => {
              if (r.status) {
                console.log(r);
                this.firebase.addUserMap(result.name, r.id, result.userId, result.playerId, result.year,
                  false, 'Coach');
                this.notification.success('Team added');
              } else {
                console.error(r.message);
                this.notification.error('Team could not be added');
              }
            });
      }
    });
  }
}
