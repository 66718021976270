<div class="row mt-2 justify-content-md-center" *ngIf="!isLoadingResults">
  <ng-container *ngFor="let msg of receivedMessagesWithAuthors">
    <ng-container *ngIf="mode==='feed'">
    <mat-card class="col-lg-8 col-md-8 mb-2">
      <mat-card-header>
        <img mat-card-avatar [src]="msg.authorInfo.profilePictureUrl" />
        <mat-card-title class="h4"
          >{{ msg.authorInfo.name }}
          {{ msg.authorInfo.lastName }}</mat-card-title
        >
        <mat-card-subtitle>
          <ng-container *ngIf="msg.authorInfo.email">
            <a
              [href]="getUserEmailLink(msg.authorInfo.email)"
              class="emailInMssg"
              >{{ msg.authorInfo.email }}</a
            >&nbsp;-&nbsp;
          </ng-container>
          {{ msg.dateTime.toMillis() | date : 'medium' }}
        </mat-card-subtitle>
      </mat-card-header>
      <ng-container *ngIf="msg.mediaType === 'image'">
        <img mat-card-image [src]="msg.mediaURL">
      </ng-container>
      <ng-container *ngIf="msg.mediaType === 'video'">
          <video mat-card-image controls>
            <source [src]="msg.mediaURL" type="video/mp4" />
          </video>
      </ng-container>
      <mat-card-content>
        <ng-container *ngIf="msg.subject">
          <p class="h5">{{ msg.subject }}</p>
        </ng-container>
        <p class="messageBody" style="white-space: pre-line">{{ msg.message }}</p>
      </mat-card-content>
      <mat-card-actions class="px-1">
        <ng-container *ngTemplateOutlet="showDefaultReaction ? defaultFavorite : customFavorite;
        context: { $implicit: msg }"></ng-container>
        <ng-container
          *ngTemplateOutlet="
            showComments ? playerComments : playerCommentsViewMore;
            context: { $implicit: msg }
          "
        ></ng-container>
        <!-- needed for delete / publish video feature
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Message options">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item type="button" (click)="deleteVideo(i)"
            <mat-icon>delete_outline</mat-icon>
            <span i18n="@@msj-delete-button">Delete Message</span>
          </button>
        </mat-menu>-->
      </mat-card-actions>
    </mat-card>
    </ng-container>
  </ng-container>
  <ng-container  *ngFor="let msg of receivedMessagesWithAuthors">
  <ng-container role="button" *ngIf="this.mode==='tiles'">
    <div class="col-4 px-1 mb-2" (click)="openMediaThread(msg)">
      <div class="post-item">
        <ng-container   *ngIf="msg.mediaType === 'image'">
          <img  [src]="msg.mediaURL">
        </ng-container>
        <ng-container *ngIf="msg.mediaType === 'video'">
          <video class="post-item">
            <source [src]="msg.mediaURL" type="video/mp4" />
          </video>
        </ng-container>
        <ng-container *ngIf="msg.mediaType === 'multiple'">
          <div>
            <ng-container *ngIf="msg.additionalMedia[0].type === 'image'">
              <img  [src]="msg.additionalMedia[0].downloadUrl">
            </ng-container>
            <ng-container *ngIf="msg.additionalMedia[0].type === 'video'">
              <video class="post-item">
                <source [src]="msg.additionalMedia[0].downloadUrl" type="video/mp4" />
              </video>
            </ng-container>
            <span class="material-symbols-outlined post-icon-type">
              filter_none
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="msg.mediaType !== 'video' && msg.mediaType !== 'image' && msg.mediaType !== 'multiple'">
          <div class="only-text-message p-5">
            <img
            src="assets/images/puravida_NavLogo.svg"
            stretch="fill"
            class=" m-auto img-responsive"/>
            <span class="material-symbols-outlined post-icon-type">
              mode_comment
            </span>
          </div>
        </ng-container>
        <div class="d-flex px-2 w-100 post-date">
          <p class="my-auto mr-auto">
            <small>{{msg.dateTime.toMillis() | date:'short'}}</small>
          </p>
        </div>
      </div>
    </div>
  </ng-container>
  </ng-container>
  <div *ngIf="receivedMessagesWithAuthors.length === 0">
    <p i18n="@@player-dashboard-messages-empty">No messages received yet</p>
  </div>
</div>

<ng-template #playerComments let-msg>
  <button
    class="like ml-3"
    mat-icon-button
    aria-label="view comments"
    (click)="openMediaThread(msg)"
  >
    <mat-icon class="mr-1">chat_bubble_border</mat-icon>
    <ng-container *ngIf="msg.commentCount && msg.commentCount > 0">
      <span>{{ msg.commentCount }}</span>
    </ng-container>
  </button>
</ng-template>

<ng-template #playerCommentsViewMore let-msg>
  <span
    i18n="@@player-dashboard-messages-view-more"
    (click)="openMediaThread(msg)"
    class="like ml-3"
    aria-label="view comments"
    >View more</span
  >
</ng-template>

<ng-template #defaultFavorite let-msg>
  <button
    *ngIf="isMessageLiked(msg.reactions)"
    class="like"
    mat-icon-button
    color="warn"
    aria-label="Liked"
    (click)="changeLikeStatus(msg)"
  >
    <mat-icon>favorite</mat-icon>
  </button>
  <button
    *ngIf="!isMessageLiked(msg.reactions)"
    class="like"
    mat-icon-button
    color="warn"
    aria-label="Like"
    (click)="changeLikeStatus(msg)"
  >
    <mat-icon>favorite_border</mat-icon>
  </button>
  <span>{{ getLikeQty(msg.reactions) }}</span>
</ng-template>

<ng-template #customFavorite let-msg>
  <button
    *ngIf="isMessageLiked(msg.reactions)"
    class="like"
    mat-icon-button
    color="warn"
    aria-label="Liked"
    (click)="changeLikeStatus(msg)"
  >
    <img src="assets/images/reaction_surf_selected.png"
    />
  </button>
  <button
    *ngIf="!isMessageLiked(msg.reactions)"
    class="like"
    mat-icon-button
    color="warn"
    aria-label="Like"
    (click)="changeLikeStatus(msg)"
  >
  <img src="assets/images/reaction_surf_outline.png"
  />
  </button>
  <span>{{ getLikeQty(msg.reactions) }}</span>
</ng-template>
