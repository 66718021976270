import { Component, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { UserInfo } from '../models/userInfo';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from '@angular/material/dialog';
import { UserInfoDialogFormComponent } from '../user-info-dialog-form/user-info-dialog-form.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationService } from '../shared/notification.service';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public displayedColumns: string[] = ['select', 'name', 'lastName', 'language', 'userId',
                                        'profilePicturePath', 'email', 'emailSubscription', 'actions'];
  public dataSource = new MatTableDataSource();
  private initialSelection = [];
  private allowMultiSelect = true;
  private selection = new SelectionModel<any>(this.allowMultiSelect, this.initialSelection);

  private userInfoList: UserInfo[];

  public title = 'User Info';

  public isLoadingResults = true;

  constructor(private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private notification: NotificationService) {}

  ngOnInit() {
    this.firebase.getUsersInfo().subscribe(data => {
      this.userInfoList = [];
      data.forEach(u => {
        this.userInfoList.push(u as UserInfo);
      });
      this.dataSource = new MatTableDataSource(this.userInfoList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.selection = new SelectionModel<UserInfo>(this.allowMultiSelect, this.initialSelection);
      this.isLoadingResults = false;
    });
  }

  doFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: UserInfo): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} userInfo ${row.name}`;
  }

  onNewUserInfo(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '55%';
    const dialogRef = this.dialog.open(UserInfoDialogFormComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.firebase.addUserInfo(result.name, result.lastName, this.translateLanguage(result.language),
          result.userId, result.profilePicturePath, result.email);
        this.notification.success('User info added');
      }
    });
  }

  onEditUserInfo(row) {
    const dialogRef = this.dialog.open(UserInfoDialogFormComponent, {
      width: '55%',
      disableClose: true,
      autoFocus: true,
      data: row
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.firebase.editUserInfo(result.userinfoId, result.name, result.lastName, this.translateLanguage(result.language),
          result.userId, result.profilePicturePath, result.email, result.emailSub);
        this.notification.success('User info edited');
      }
    });
  }

  onDeleteUserInfo(row) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '30%',
      disableClose: true,
      autoFocus: true,
      data: { action: 'delete user info', object: row }
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.firebase.deleteUserInfo(row.userinfoId);
        this.notification.success('User info deleted');
      }
    });
  }

  translateLanguage(value: string) {
    if (value === '2') {
      return 'es';
    } else {
      return 'en';
    }
  }

}
