import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMessage } from 'src/app/models/app-message';
import { Comment } from 'src/app/models/comment';
import { UserInfo } from '../models/userInfo';
import { Player } from 'src/app/models/player';
import { Team } from 'src/app/models/team';
import { FirebaseService } from '../shared/firebase.service';
import { NotificationService } from '../shared/notification.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from '../auth/auth.service';
import { RoleService } from '../shared/role.service';
import { flatMap, map, take } from 'rxjs/operators';
import { type } from 'os';
import { environment } from '../../environments/environment';
import { MediaMetadata } from '../models/mediaMetadata';

@Component({
  selector: 'app-media-thread',
  templateUrl: './media-thread.component.html',
  styleUrls: ['./media-thread.component.css']
})
export class MediaThreadComponent implements OnInit {

  mediaId: string;
  mediaType = 'message';
  selectedMessage: AppMessage;

  comments: Comment[] = [];
  commentsWithAuthorInfo: Comment[] = [];
  commentAuthorsImgs: string[] = [];

  isLoadingResults = true;

  myUserId: string;
  playerDashboardLink = '/player-dashboard/';
  playerId: string;

  //carousel type
  showCarousel = false;
  currentSlideIndex: number = 0;
  mediaObjects: MediaMetadata[] = []

  public commentsForm = new FormGroup({
    action: new FormControl('new'),
    commentBody: new FormControl('', Validators.required),
  });

  constructor(private firebase: FirebaseService
    , private notification: NotificationService
    , private authService: AuthService
    , private router: Router
    , private route: ActivatedRoute
    , private functions: AngularFireFunctions
    , @Inject(LOCALE_ID) public locale: string
    , private roleService: RoleService) { }

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.mediaType = params['type'];
      this.mediaId = params['id'];
      this.playerId = params['player']

      if (!this.mediaId) {
        this.notification.error(this.getErrorMessage());
        this.router.navigate(['/home']);
      }
      this.firebase.getMedia(this.mediaId, 'message')
      .pipe(take(1))
      .subscribe((msg: AppMessage) => {
        this.isLoadingResults = true;
        if (msg.message && msg.dateTime) {
          this.firebase.getUserInfoById(msg.userId)
          .pipe(take(1))
          .subscribe((info: any[]) => {
            const completeMsg = msg;
            const author = info[0] as UserInfo;
            completeMsg.authorInfo = author;
            if (!author.profilePictureUrl) {
              this.firebase.getProfilePicture(author.profilePicturePath || 'profilePictures/default.png')
              .pipe(take(1))
              .subscribe((pic: string) => {
                completeMsg.authorInfo.profilePictureUrl = pic;
                this.selectedMessage = completeMsg;
              });
            } else {
              this.selectedMessage = completeMsg;
            }
          });
        }
        if(msg.mediaType === 'multiple'){
          this.showCarousel = true;
          this.mediaObjects = msg.additionalMedia;
        }
        this.isLoadingResults = false;
      });
    });

    this.firebase.getMediaComments(this.mediaId, 'message')
      //.pipe(take(1))
      .subscribe((comms: Comment[]) => {
        this.isLoadingResults = true;
        if(comms != null) {
          comms.forEach(comm =>{
            if (this.commentNotLoaded(comm)) {
              this.firebase.getUserInfoById(comm.userId)
              .pipe(take(1))
              .subscribe((info: any[]) => {
                const completeComment = comm;
                const author = info[0] as UserInfo;
                completeComment.authorInfo = author;
                if (!author.profilePictureUrl) {
                  this.firebase.getProfilePicture(author.profilePicturePath || 'profilePictures/default.png')
                  .pipe(take(1))
                  .subscribe((pic: string) => {
                    completeComment.authorInfo.profilePictureUrl = pic;
                    if (this.commentNotLoaded(completeComment)) {
                      this.commentsWithAuthorInfo.push(completeComment);
                      this.commentsWithAuthorInfo.sort((a, b) => {
                        return a.dateTime > b.dateTime ? 1 : a.dateTime < b.dateTime ? -1 : 0;
                      });
                    }
                  });
                } else {
                  if (this.commentNotLoaded(completeComment)) {
                    this.commentsWithAuthorInfo.push(completeComment);
                    this.commentsWithAuthorInfo.sort((a, b) => {
                      return a.dateTime > b.dateTime ? 1 : a.dateTime < b.dateTime ? -1 : 0;
                    });
                  }
                }
              });
            }
          });
        }
        this.isLoadingResults = false;
      });
    this.myUserId = this.authService.getUser.uid;
    const role = await this.roleService.getRole(this.myUserId);
    if (role === 'Coach') {
      this.setCoachOptions();
    }
    this.isLoadingResults = false;
  }

  commentNotLoaded(c: Comment) : boolean {
    const exist = this.commentsWithAuthorInfo.findIndex(com => com.commentId === c.commentId);
    return exist === -1 ? true : false;
  }

  getUserEmailLink(email: string): string {
    return 'mailto:' + email + '?subject=Reply to evaluation';
  }

  changeLikeStatus(msg: AppMessage) {
    if(!msg.messageId) {
      msg.messageId = this.mediaId;
    }
    if (msg.reactions && msg.reactions[this.authService.getUser.uid]) {
      delete msg.reactions[this.authService.getUser.uid];
    } else if (msg.reactions) {
      msg.reactions[this.authService.getUser.uid] = 1;
    } else {
      msg.reactions = {};
      msg.reactions[this.authService.getUser.uid] = 1;
    }

    this.firebase.updateMessageReactions(msg);
  }

  isMessageLiked(reactions: any) {
    return (reactions && reactions[this.authService.getUser.uid]);
  }

  getLikeQty(reactions: any) {
    let qty = 0;
    if (reactions) { Object.keys(reactions).forEach(k => qty += reactions[k]); }
    return (qty > 0) ? qty : '';
  }

  async onPostComment() {
    const body: string = this.commentsForm.get('commentBody').value;
    const newComment: Comment = {
      userId: this.myUserId,
      comment: body,
      dateTime: new Date(),
      messageId: this.mediaId,
    };
    this.firebase.addCommentToMedia(this.mediaId, 'message', newComment);

    if(this.selectedMessage.commentCount) {
      this.selectedMessage.commentCount++;
    } else {
      this.selectedMessage.commentCount = 1;
    }
    this.firebase.increaseMediaCommentCount(this.mediaId, 'message', this.selectedMessage.commentCount);

    this.notification.success(this.getSuccessMessage());
    this.commentsForm.get('commentBody').patchValue('');
    this.commentsForm.get('commentBody').markAsUntouched();
  }

  getErrorMessage(): string {
    return (this.locale.includes('en') ? 'Can\'t load media' : 'Error al cargar comentarios');
  }

  getSuccessMessage(): string {
    return (this.locale.includes('en') ? 'Comment sent' : 'Comentario enviado');
  }

  setCoachOptions() {
    // this.isCoach = true;
    this.playerDashboardLink = '/player-dashboard/' + this.playerId + '?isCoach=true';
  }

  get showComments(): boolean {
    return environment.playerDashboard.showComments;
  }

  get showDefaultReaction(): boolean {
    return environment.playerDashboard.defaultReaction;
  }

  // Function to navigate carousel
  changeSlide(direction: string) {
    if (direction === 'next') {
      this.currentSlideIndex = (this.currentSlideIndex + 1) % this.mediaObjects.length;
    } else if (direction === 'prev') {
      this.currentSlideIndex = (this.currentSlideIndex - 1 + this.mediaObjects.length) % this.mediaObjects.length;
    }
  }

}
