<div class="stories">
    <div *ngIf="!fetched" class="story-loader">
        <div class="example-loading-shade" *ngIf="isLoadingResults">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <!-- spinner
        <img alt="Loading..." src="/assets/img/loader.gif" height="32" width="32" />
        -->
    </div>
    <div *ngIf="fetched" class="story-inner">
        <div class="d-flex justify-content-center story-container">
            <div *ngIf="isCurrentImg()" class="d-flex justify-content-center story-content">
                <img [src]="shownSrc" alt="" class="story-content-img"/>
            </div>
            <div *ngIf="isCurrentVideo()" class="d-flex justify-content-center story-content">
                <video class="" autoplay playsinline preload="none" [src]="shownSrc" style="display: block;"></video>
                <!--
            <video autoplay playsinline class="story-video" src="..."></video>
            -->
            </div>
        </div>
        <div class="story-upper-panel d-flex flex-row justify-content-between">
            <!-- icon
            <app-story-icon
            *ngFor="let story of visibleStories; let i = index"
            [ngStyle]="styleTransform[i]"
            [index]="i"
            [story]="story"
            ></app-story-icon>-->
            <div class="d-flex align-items-center">
                <img class="profile-pic" [src]="journeyIconRoute" alt="" width="32" height="32"/>
                <span style="color: white;" class="ml-1">My Journey</span>
                <span style="color: silver;" class="ml-1">{{shownDate}}</span>
            </div>
            <div class="d-flex align-items-center">
                <button mat-icon-button aria-label="close" style="color: white;" (click)="clickClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

        </div>
        <div class="story-progress-panel d-flex flex-row">
            <ng-container *ngFor="let story of stories; let i = index">
                <!--<div *ngIf="i===currentStoryIndex" class="flex-fill">
                    <progress max="100" value="70"></progress>
                </div>-->
                <div class="flex-fill story-progress-step" [ngClass]="setIndicatorBgColor(i)">
                    <div *ngIf="i===currentStoryIndex" class="current-story-indicator"></div>
                </div>
            </ng-container>

        </div>

    </div>
    <!--<div
        *ngIf="storyIndex > 3"
        class="slide-arrow slide-arrow-left"
        (click)="clickPrevStory()"
    >
        <div class="slide-arrow-icon slide-arrow-icon-left"></div>
    </div>
    <div
        *ngIf="stories.length > storyIndex + 7"
        class="slide-arrow slide-arrow-right"
        (click)="clickNextStory()"
    >
        <div class="slide-arrow-icon slide-arrow-icon-right"></div>
    </div>-->
</div>

