import { Component, OnInit, Input, ChangeDetectorRef,  EventEmitter, Output} from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';
import { finalize, tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.css']
})
export class UploadTaskComponent implements OnInit {
  @Input() file: File;
  @Input() playerId: string;
  @Input() teamId: string;
  @Input() uploadedBy: string;
  @Input() type: string;
  @Input() isStory?: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() result = new EventEmitter<any>();

  uploadComplete = false;

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  canceled: Observable<boolean>;
  firebaseFormat: string;

  mediaFolder = 'playersMedia';
  drillsFolder = 'drills';
  teamLogosFolder = 'teams/logos';
  storageFolder: string;
  path: string;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
  ) {}

  ngOnInit() {

    if (this.type === 'media' || this.type === 'message') {

      this.storageFolder = this.mediaFolder;
      this.startUpload();
    }
    if (this.type === 'drill') {

      this.storageFolder = this.drillsFolder;
      this.startUpload();
    }
    if (this.type === 'teamLogo') {
      this.storageFolder = this.teamLogosFolder;
      this.startUpload();
    }
  }

 startUpload() {

  const typeFromName = this.file.name.split('.');

   // The storage path
   if (this.type === 'teamLogo') {
    this.path = `${this.storageFolder}/${this.playerId}_logo.${this.file.name.split('.')[typeFromName.length - 1]}`;
   } else {
    this.path = `${this.storageFolder}/${this.playerId}_${Date.now()}_${this.file.name}`;
   }
    // Reference to storage bucket
    const ref = this.storage.ref(this.path);

    // The main task
    this.task = this.storage.upload(this.path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize(async () => {
        const uploadTime = new Date();
        this.downloadURL = await ref.getDownloadURL().toPromise();
        this.firebaseFormat = this.file.type.split('/')[1];

        if (this.type === 'media') {
          this.db
            .collection('playersmedia')
            .add({
              downloadUrl: this.downloadURL,
              storagePath: this.path,
              fileName: this.file.name,
              type: this.file.type.split('/')[0],
              uploadDate: new Date(),
              playerId: this.playerId,
              teamId: this.teamId,
              uploadedByName: this.uploadedBy,
              format: this.file.name.split('.')[typeFromName.length - 1],
              firebaseFormat: this.file.type.split('/')[1],
              isStory: this.isStory
            });
        }
        if (this.type === 'drill') {
          console.log('uploaded drill');
          console.log(this.downloadURL);
          console.log(this.firebaseFormat);

          this.result.emit( {
            path: this.path,
            downloadURL: this.downloadURL,
            format: this.firebaseFormat
          });
          this.uploadComplete = true;
        }
        if (this.type === 'teamLogo') {
          this.result.emit( {
            path: this.path,
            downloadURL: this.downloadURL,
            format: this.firebaseFormat
          });
          this.uploadComplete = true;
        }
        if (this.type === 'message') {
          this.result.emit( {
            path: this.path,
            downloadURL: this.downloadURL,
            format: this.firebaseFormat,
            //storagePath: this.path,
            fileName: this.file.name,
            type: this.file.type.split('/')[0],
          });
          this.uploadComplete = true;
        }
      })
    );
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }
}
