<div class="row">
  <div
    *ngIf="category === 'Físicas'"
    class="statisticIcon my-auto ml-3"
    style="background-color: #00ff87;"
  >
    <img
      [src]="categoryIcon"
      class="statisticImage"
      width="40"
      height="40"
    />
  </div>
  <div
    *ngIf="category === 'Mentalidad'"
    class="statisticIcon my-auto ml-3"
    style="background-color: #e74cf5;"
  >
    <img
      [src]="categoryIcon"
      class="statisticImage"
      width="40"
      height="40"
    />
  </div>
  <div
    *ngIf="category === 'Táctica'"
    class="statisticIcon my-auto ml-3"
    style="background-color: #ff015b;"
  >
    <img
      [src]="categoryIcon"
      class="statisticImage"
      width="40"
      height="40"
    />
  </div>
  <div
    *ngIf="category === 'Técnica'"
    class="statisticIcon my-auto ml-3"
    style="background-color: #05f1ff;"
  >
    <img
      [src]="categoryIcon"
      class="statisticImage"
      width="40"
      height="40"
    />
  </div>
  <div class="col-lg-3 categoryDashboardTitle my-auto pl-0 col-4 col-sm-10 mt-sm-3 my-md-auto col-md-3">
    <span class="categoryDashboardLabel">{{ getCategoryLocale(category) }}</span>
  </div>
  <div class="col-lg-6 my-auto col-4 col-sm-10 col-md-6">
    <app-category-evolution-dashboard
      *ngIf="validEvolution"
      [evolutionValues]="evolutionValues"
      [evolutionDates]="evolutionDates"
    ></app-category-evolution-dashboard>
  </div>
</div>
<div class="example-loading-shade" *ngIf="isLoadingTeamResults">
  <mat-spinner *ngIf="isLoadingTeamResults"></mat-spinner>
</div>
<div *ngIf="skillStatsFull" class="table-responsive">
  <table class="table table-borderless table-striped table-sm">
    <thead>
      <th>Skill</th>
      <th class="text-right">
        <span class="tooltip-underline"
        matTooltip="Total evaluations"
        matTooltipPosition="above"
        i18n="@@skill-cat-details-thead-ts-title"
        i18n-matTooltip="@@skill-cat-details-thead-ts-tooltip">TE</span>
      </th>
      <th class="text-right">
        <span class="tooltip-underline"
        matTooltip="Average"
        matTooltipPosition="above"
        i18n="@@skill-cat-details-thead-pct-title"
        i18n-matTooltip="@@skill-cat-details-thead-pct-tooltip"
        >AVG</span>
      </th>
    </thead>
    <tbody>
        <ng-container *ngFor="let skill of skillStats">
            <tr *ngIf="skill.evalCount !== 0">
              <td>
                <ng-container [ngSwitch]="detailType">
                  <a *ngSwitchCase="'player'" [href]="getDetailDashboardLink(skill.skillId)">{{skill.skillName}}</a>
                  <p *ngSwitchDefault><small>{{skill.skillName}}</small></p>
                </ng-container>
              </td>
              <td class="text-right">{{skill.evalCount}}</td>
              <td class="text-right" [ngClass]="skill.normalizedPct < 0.7 ? 'error-text' : ''">{{skill.normalizedPct | percent: '1.0-0'}}</td>
            </tr>
        </ng-container>
    </tbody>
</table>
</div>
