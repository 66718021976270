import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { RoleService } from '../shared/role.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.css']
})
export class UploaderComponent implements OnInit {
  @ViewChild('filesInput')
  filesInputVariable: ElementRef;

  isHovering: boolean;
  isCoach: boolean;
  isStory: boolean;
  isLoadingResults = true;

  files: File[] = [];
  playerId: string;
  teamId: string;
  uploadedBy: string;

  playerDashboardLink = '/player-dashboard/';

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private roleService: RoleService
  ) {}

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.playerId = params['playerId'];
      this.teamId = params['teamId'];
      this.uploadedBy = params['uploadedBy'];
      this.isStory =  this.stringToBoolean(params['story']);
      // this.isCoach = params['isCoach'];

      this.playerDashboardLink += this.playerId;

      if (this.isCoach) {
        this.setCoachOptions();
      }

      if (!this.playerId || !this.teamId || !this.uploadedBy) {
        this.router.navigate(['/home']);
      }
    });

    const role = await this.roleService.getRole(this.authService.user.uid);
    if (role === 'Coach') {
      this.setCoachOptions();
    }
    this.isLoadingResults = false;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
  }

  cambioArchivo(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.files.push(event.target.files.item(i));
    }
    this.resetInput();
  }

  resetInput() {
    this.filesInputVariable.nativeElement.value = '';
  }

  setCoachOptions() {
    this.isCoach = true;
    this.playerDashboardLink += '?isCoach=true';
  }

  stringToBoolean(str: string): boolean {
    const lowerCaseStr = str.toLowerCase();
    return lowerCaseStr === 'true' || lowerCaseStr === '1';
  }
}
