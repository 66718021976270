import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateDrillAdminsComponent } from './create-drill-admins/create-drill-admins.component';
import { DrillComponent } from './drill/drill.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PlayerDashboardComponent } from './player-dashboard/player-dashboard.component';
import { PlayerDrillsComponent } from './player-drills/player-drills.component';
import { PlayersComponent } from './players/players.component';
import { ProfileComponent } from './profile/profile.component';
import { AppAdminGuardService } from './shared/app-admin-guard.service';
import { AppAuthGuardService } from './shared/app-auth-guard.service';
import { SignUpProfileComponent } from './sign-up-profile/sign-up-profile.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SkillListEditComponent } from './skill-list-screen/skill-list-edit/skill-list-edit.component';
import { SkillListComponent } from './skill-list-screen/skill-list/skill-list.component';
import { SkillsComponent } from './skills/skills.component';
import { SupportedsComponent } from './supporteds/supporteds.component';
import { TeamDashboardComponent } from './team-dashboard/team-dashboard.component';
import { TeamUserListComponent } from './team-user-list/team-user-list.component';
import { TeamsComponent } from './teams-screen/teams/teams.component';
import { UploaderComponent } from './uploader/uploader.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UsermapComponent } from './usermap/usermap.component';
import { CreateMessageComponent } from './messages/create-message/create-message.component';
import { SkillScoreDetailComponent } from './skill-score-detail/skill-score-detail.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MediaThreadComponent } from './media-thread/media-thread.component';
import { StoryViewerComponent } from './messages/story-viewer/story-viewer.component';


const routes:  Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AppAuthGuardService] },
  { path: 'login', component:  LoginComponent},
  { path: 'sign-up', component:  SignUpComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'sign-up-profile', component:  SignUpProfileComponent},
  { path: 'profile', component: ProfileComponent, canActivate: [AppAuthGuardService] },
  { path: 'players', component: PlayersComponent, canActivate: [AppAuthGuardService, AppAdminGuardService] },
  { path: 'player-drills', component: PlayerDrillsComponent, canActivate: [AppAuthGuardService, AppAdminGuardService] },
  { path: 'teams', component: TeamsComponent, canActivate: [AppAuthGuardService, AppAdminGuardService] },
  { path: 'users', component: UsermapComponent, canActivate: [AppAuthGuardService, AppAdminGuardService] },
  { path: 'users-info', component: UserInfoComponent, canActivate: [AppAuthGuardService, AppAdminGuardService] },
  { path: 'skills', component: SkillsComponent, canActivate: [AppAuthGuardService, AppAdminGuardService] },
  { path: 'supporteds', component: SupportedsComponent, canActivate: [AppAuthGuardService, AppAdminGuardService] },
  { path: 'team-dashboard/:teamId', component: TeamDashboardComponent, canActivate: [AppAuthGuardService] },
  { path: 'player-dashboard/:playerId', component: PlayerDashboardComponent, canActivate: [AppAuthGuardService]},
  { path: 'upload-media', component: UploaderComponent, canActivate: [AppAuthGuardService] },
  { path: 'skill-list', component: SkillListComponent, canActivate: [AppAuthGuardService, AppAdminGuardService] },
  { path: 'skill-list-edit/:skillListId', component: SkillListEditComponent, canActivate: [AppAuthGuardService, AppAdminGuardService] },
  { path: 'create-drill-admins', component: CreateDrillAdminsComponent, canActivate: [AppAuthGuardService] },
  { path: 'team-user-list', component: TeamUserListComponent, canActivate: [AppAuthGuardService] },
  { path: 'drill', component: DrillComponent},
  { path: 'evaluation/:playerId/:date', component: EvaluationComponent, canActivate: [AppAuthGuardService]},
  { path: 'evaluation/:playerId', component: EvaluationComponent, canActivate: [AppAuthGuardService]},
  { path: 'create-message', component: CreateMessageComponent, canActivate: [AppAuthGuardService]},
  { path: 'skill-history/:playerId/:skillId', component: SkillScoreDetailComponent, canActivate: [AppAuthGuardService]},
  { path: 'media-thread', component: MediaThreadComponent, canActivate: [AppAuthGuardService]},
  { path: 'stories', component: StoryViewerComponent, canActivate: [AppAuthGuardService]},
  { path: '**', redirectTo: 'home' }
  // { path: 'details/:id', component: EditUserComponent, resolve:{data : EditUserResolver} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
