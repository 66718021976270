import { Component, OnInit, OnDestroy, Inject, LOCALE_ID, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AppMessage } from 'src/app/models/app-message';
import { MediaMetadata } from 'src/app/models/mediaMetadata';
import { Timestamp } from '@firebase/firestore';
import { UserInfo } from 'src/app/models/userInfo';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FirebaseService } from '../../shared/firebase.service';
import { Location, formatDate } from '@angular/common';


@Component({
  selector: 'app-story-viewer',
  templateUrl: './story-viewer.component.html',
  styleUrls: ['./story-viewer.component.css']
})
export class StoryViewerComponent implements OnInit, OnDestroy{
  //@Input() stories: AppMessage[] = [];

  stories: MediaMetadata[] = [];

  isLoadingResults = true;
  public fetched: boolean = false;
  public styleTransform = [];
  public visibleStories: AppMessage[] = [];
  public imgUrl: string = '/assets/img/userdata/';
  public storyIndex: number = 0;
  public liveImg = "https://www.oberlo.com/media/1603957802-image17-3.png?w=1824&fit=max";
  public liveVid = "../../../../assets/vidExample.mp4";
  public shownSrc = '';
  public shownDate: string;
  public currentStoryContent;
  playerDashboardLink = '/player-dashboard/';
  playerId: string;

  public timeRemaining = 3; // Set initial time
  public currentStoryIndex: number; // Store interval reference
  intervalId: number; // Store interval reference

  constructor(
    private _route: ActivatedRoute,
    private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  ngOnInit(): void {
    /*
    let imgStory: MediaMetadata = new MediaMetadata();
    imgStory.downloadUrl = this.liveImg;
    imgStory.fileName = this.liveImg;
    imgStory.playerId = '6Ki1syuqt0dCIc227gqODRqcvqJ2';
    imgStory.storagePath = this.liveImg;
    imgStory.teamId = '6Ki1syuqt0dCIc227gqODRqcvqJ2'
    imgStory.type = 'image';
    imgStory.uploadDate = new Date();
    imgStory.uploadedByName = 'test';
    imgStory.format = 'png';
    imgStory.firebaseFormat= 'png';
    imgStory.id='1'
    imgStory.isStory = true;

    let videoStory: MediaMetadata = new MediaMetadata();
    videoStory.downloadUrl = this.liveImg;
    videoStory.fileName = this.liveImg;
    videoStory.playerId = '7Veda6AnjwPDjV1yxb30azf8rtz1';
    videoStory.storagePath = this.liveImg;
    videoStory.teamId = 'default';
    videoStory.type = 'video';
    imgStory.uploadDate = new Date();
    videoStory.uploadedByName = 'test';
    videoStory.format = 'mp4';
    videoStory.firebaseFormat= 'mp4';
    videoStory.id='2'
    videoStory.isStory = true;

    this.stories = [ imgStory, videoStory, imgStory ];
    */
    this.route.queryParams.subscribe(params => {
      this.playerId = params['playerId'];
      if (!this.playerId) {
        this.clickClose();
      }
      this.firebase.getStories(this.playerId)
      .pipe(take(1))
      .subscribe
      ((fetchedStories) => {
        for (let i = 0; i < fetchedStories.length; i++) {
          this.stories.push(fetchedStories[i]);
        }
        this.fetched = true;
        this.isLoadingResults = false;
        this.showStories();
      });
    });
  }

  ngOnDestroy() {
    this.currentStoryIndex = 0;
  }

  async showStories() {
    for(this.currentStoryIndex = 0; this.currentStoryIndex < this.stories.length; this.currentStoryIndex++){
      this.setCurrentStory();
      await new Promise(f => setTimeout(f, 5000));
    }
    this.clickClose();
  }

  setCurrentStory(): void {
    var currDate = this.firestoreTimestampToDate(this.stories[this.currentStoryIndex].uploadDate) ;

    this.currentStoryContent = this.stories[this.currentStoryIndex].type; //.mediaType;
    this.shownSrc = this.stories[this.currentStoryIndex].downloadUrl;//.mediaURL;
    this.shownDate = this.getFormattedDate(currDate);
  }

  setIndicatorBgColor(i): string {
    let bgColorClass = 'unplayed-story';
    if(i < this.currentStoryIndex){
      bgColorClass = 'previous-story';
    }
    if(i === this.currentStoryIndex){
      bgColorClass = 'current-story';
    }
    if(i > this.currentStoryIndex){
      bgColorClass = 'unplayed-story';
    }
    return bgColorClass;
  }

  public clickPrevStory(): void {
    if (this.storyIndex > 3) {
      this.storyIndex = this.storyIndex - 4;
    }
    this.visibleStories = this.populateVisibleStories();
  }

  public clickNextStory(): void {
    if (this.stories.length > this.storyIndex + 7) {
      this.storyIndex = this.storyIndex + 4;
    }
    this.visibleStories = this.populateVisibleStories();
  }

  public clickClose(): void {
    this.location.back();
  }

  setCoachOptions() {
    // this.isCoach = true;
    this.playerDashboardLink = '/player-dashboard/' + this.playerId + '?isCoach=true';
  }

  private populateVisibleStories(): any {
    const uptoIndex =
      this.stories.length > this.storyIndex + 8
        ? this.storyIndex + 8
        : this.stories.length;
    let tempStories = [];
    for (let i = this.storyIndex; i < uptoIndex; i++) {
      tempStories.push(this.stories[i]);
    }
    return tempStories;
  }

  get journeyIconRoute(): string {
    return environment.versions.journeyIcon;
  }

  isCurrentImg(): boolean {
    return this.currentStoryContent == 'image';
  }

  isCurrentVideo(): boolean {
    return this.currentStoryContent == 'video';
  }

  getFormattedDate(storyDate: Date): string {
    const now = new Date();
    const diffInSeconds = (now.getTime() - storyDate.getTime()) / 1000;
    const secondsInMinute = 60;
    const minutesInHour = 60;
    const hoursInDay = 24;

    // If within a day, display "x hours ago" or "x minutes ago"
    if (diffInSeconds < hoursInDay * secondsInMinute * minutesInHour) {
      if (diffInSeconds < minutesInHour * secondsInMinute) {
        return `${Math.floor(diffInSeconds / secondsInMinute)} minutes ago`;
      } else {
        return `${Math.floor(diffInSeconds / (minutesInHour * secondsInMinute))} hours ago`;
      }
    }
    // Otherwise, display "Month day, year"
    return formatDate(storyDate, 'longDate', this.locale);

  }

  firestoreTimestampToDate(timestamp: any): Date {
    const seconds = timestamp.seconds;
    const nanoseconds = timestamp.nanoseconds;
    // Create a Date object from the seconds, adjusting for nanoseconds if necessary
    const date = new Date(seconds * 1000); // Multiply seconds by 1000 to get milliseconds
    if (nanoseconds > 0) {
      date.setMilliseconds(date.getMilliseconds() + (nanoseconds / 1000000));
    }
    return date;
  }

  private createStyle(): void {
    this.stories.map((story, index) => {
      this.styleTransform.push({
        transform: 'translateX(' + 80 * index + 'px)',
      });
    });
  }

}
