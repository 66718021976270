<app-header></app-header>
<div class="container main-div">
    <div class="dashboardHeader row">
        <div class="dashboardInfo col">
            <h1 class="dashboardTitle">
                <span i18n="@@create-message-title">Send message to</span>
                <span> {{entityName}}</span>
            </h1>
        </div>
    </div>
    <ng-container
                *ngTemplateOutlet="
                isDebrief ? debriefForm : normalForm;"
    ></ng-container>
</div>
<ng-template #normalForm>
    <form [formGroup]="messagesForm" class="normal-form py-4">
        <div class="controles-container">
            <div class="row">
                <mat-form-field color="accent" class="col-md-4"  floatLabel="always">
                    <mat-label i18n="@@create-message-subject-label">Subject</mat-label>
                    <mat-select formControlName="messageSubject" [(value)]="messageContent">
                        <mat-option value="Development recommendation"
                        i18n="@@create-message-subject-options-dev-recommendation">
                            Development recommendation
                        </mat-option>
                        <mat-option value="Debrief"
                        i18n="@@create-message-subject-options-debrief">
                            Debrief
                        </mat-option>
                        <mat-option value="Intake"
                        i18n="@@create-message-subject-options-intake">
                            Intake
                        </mat-option>
                        <mat-option value="Progress note"
                        i18n="@@create-message-subject-options-progress-note">
                            Progress note
                        </mat-option>
                        <mat-option value="position evaluation"
                        i18n="@@create-message-subject-options-position-evaluation">
                            Position evaluation
                        </mat-option>
                        <mat-option value="first experience"
                        i18n="@@create-message-subject-options-pv-first-exp">
                            Pura Vida first experience
                        </mat-option>
                        <mat-option value="private session"
                        i18n="@@create-message-subject-options-pv-private">
                            Pura Vida private session
                        </mat-option>
                        <mat-option value="onboarding"
                        i18n="@@create-message-subject-options-pv-onboarding">
                            Pura Vida onboarding
                        </mat-option>
                        <mat-option value="training journal"
                        i18n="@@create-message-subject-options-pv-training-journal">
                            Pura Vida training journal
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="col" color="accent">
                    <mat-label  i18n="@@create-message-body-label">Message body</mat-label>
                    <textarea matInput cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8"
                    formControlName="messageBody"></textarea>
                    <mat-error  i18n="@@create-message-body-error">Please fill this field</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <div class="dropzoneSmall my-1" appDropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)" [class.hovering]="isHovering">
                    <mat-icon font>movie</mat-icon>
                    <h2 i18n="@@create-message-upload-header">Drag and drop the media here</h2>
                    <label for="filesInput" class="custom-file-upload">
                        <i class="fa fa-cloud-upload"></i> <span i18n="@@create-message-upload-label">Select file</span>
                    </label>
                    <p class="mx-5 mt-2 text-center"
                    i18n="@@create-message-upload-video-note">
                        Note: when uploading a video, the recommended filetype is mp4. The .MOV files can have issues when playing in android devices.
                    </p>
                    <input
                        #filesInput
                        id="filesInput"
                        class="custom-file-input"
                        type="file"
                        multiple
                        (change)="cambioArchivo($event)"
                    />
                </div>
            </div>
            <div class="row"  *ngIf="PreSubmitFiles.length>0">
                <div  class="col mx-0 pt-2">
                    <h3 i18n="@@create-message-upload-selected-files-header">Selected files</h3>
                </div>
            </div>
            <div class="row" *ngFor="let preSubmitFile of PreSubmitFiles">
                <div  class="col mx-0">
                    <p>{{ preSubmitFile.name }}</p>
                </div>
            </div>
            <div class="row">
                <div *ngFor="let file of files" class="col">
                    <h2 i18n="@@create-message-upload-progress-files-header">Upload progress</h2>
                    <app-upload-task
                        [file]="file"
                        [playerId]="entityId"
                        [teamId]="entityId"
                        [uploadedBy]="entityName"
                        [type]="'message'"
                        (result)="finalizeUploadFile($event)"
                    ></app-upload-task>
                </div>
            </div>
        </div>
        <div class="button-row" mat-dialog-actions>
            <button mat-raised-button color="accent" type="submit" [disabled]="messagesForm.invalid" (click)="onSubmitMessage()" class="py-2">
                Send Message
            </button>
        </div>
    </form>
</ng-template>

<ng-template #debriefForm>
    <form [formGroup]="messageDebriefForm" class="normal-form py-4">
        <div class="controles-container">
            <div class="row">
                <mat-form-field class="col" color="accent">
                    <mat-label  i18n="@@create-message-debrief-today-work-label">What did we work on today?</mat-label>
                    <textarea matInput cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8"
                    formControlName="whatDidWeWorkToday"></textarea>
                    <mat-error  i18n="@@create-message-whatDidWeWorkToday-error">Please fill this field</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="col" color="accent">
                    <mat-label  i18n="@@create-message-debrief-today-well-label">What went well about today’s session?</mat-label>
                    <textarea matInput cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8"
                    formControlName="whatWentWellToday"></textarea>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field class="col" color="accent">
                    <mat-label  i18n="@@create-message-debrief-today-improve-label">What is worth improving about today’s session?</mat-label>
                    <textarea matInput cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8"
                    formControlName="whatToImprove"></textarea>
                </mat-form-field>
            </div>
            <div class="row">
                <div class="dropzoneSmall my-1" appDropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)" [class.hovering]="isHovering">
                    <mat-icon font>movie</mat-icon>
                    <h2 i18n="@@create-message-upload-header">Drag and drop the media here</h2>
                    <label for="filesInput" class="custom-file-upload">
                        <i class="fa fa-cloud-upload"></i> <span i18n="@@create-message-upload-label">Select file</span>
                    </label>
                    <p class="mx-5 mt-2 text-center"
                    i18n="@@create-message-upload-video-note">
                        Note: when uploading a video, the recommended filetype is mp4. The .MOV files can have issues when playing in android devices.
                    </p>
                    <input
                        #filesInput
                        id="filesInput"
                        class="custom-file-input"
                        type="file"
                        (change)="cambioArchivo($event)"
                    />
                </div>
            </div>
            <div class="row"  *ngIf="PreSubmitFiles.length>0">
                <div  class="col mx-0 pt-2">
                    <h3 i18n="@@create-message-upload-selected-files-header">Selected files</h3>
                </div>
            </div>
            <div class="row" *ngFor="let preSubmitFile of PreSubmitFiles">
                <div  class="col mx-0">
                    <p>{{ preSubmitFile.name }}</p>
                </div>
            </div>
            <div class="row">
                <div *ngFor="let file of files" class="col">
                    <h2 i18n="@@create-message-upload-progress-files-header">Upload progress</h2>
                    <app-upload-task
                        [file]="file"
                        [playerId]="entityId"
                        [teamId]="entityId"
                        [uploadedBy]="entityName"
                        [type]="'message'"
                        (result)="finalizeUploadFile($event)"
                    ></app-upload-task>
                </div>
            </div>
        </div>
        <div class="button-row" mat-dialog-actions>
            <button mat-raised-button color="accent" type="submit" [disabled]="messageDebriefForm.invalid" (click)="onSubmitMessage()" class="py-2">
                Send Message
            </button>
        </div>
    </form>
</ng-template>