<div class="my-3" style="display: block">
    <canvas
        baseChart
        [datasets]="radarChartData"
        [labels]="radarChartLabels"
        [options]="radarChartOptions"
        [chartType]="radarChartType"
    ></canvas>
</div>

