<app-header></app-header>
<div class="container main-div">
    <div class="example-loading-shade" *ngIf="isLoadingResults">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoadingResults" class="container">
        <nav aria-label="breadcrumb">
                <ol class="breadcrumb actionsBg mt-2">
                        <li class="breadcrumb-item">
                                <a [href]="playerDashboardLink"
                                        class="mat-row-link"
                                        i18n="@@media-thread-breadcrumb-player-dashboard-link">
                                        Player information
                                </a>
                        </li>
                        <li class="breadcrumb-item active"
                                aria-current="page"
                                i18n="@@media-thread-breadcrumb-message-label">
                                Message
                        </li>
                </ol>
        </nav>
        <div class="d-flex justify-content-center">
            <!--show comment here-->
            <ng-container *ngIf="mediaType==='message' && selectedMessage">
                <mat-card class="col-lg-10 col-md-8 px-md-2 mx-2 mb-2">
                    <mat-card-header>
                        <img mat-card-avatar [src]="selectedMessage.authorInfo.profilePictureUrl"/>
                        <mat-card-title class="h4">{{selectedMessage.authorInfo.name}} {{selectedMessage.authorInfo.lastname}}</mat-card-title>
                        <mat-card-subtitle>
                            <!-- <ng-container *ngIf="selectedMessage.authorInfo.email">
                                <a [href]="getUserEmailLink(selectedMessage.authorInfo.email)" class="emailInMssg">{{selectedMessage.authorInfo.email}}</a>&nbsp;-&nbsp;
                            </ng-container> -->
                            {{selectedMessage.dateTime.toMillis() | date:'medium'}}
                        </mat-card-subtitle>
                    </mat-card-header>
                    <ng-container *ngIf="selectedMessage.mediaType === 'image'">
                        <img mat-card-image [src]="selectedMessage.mediaURL">
                    </ng-container>
                    <ng-container *ngIf="selectedMessage.mediaType === 'video'">
                        <video mat-card-image controls>
                            <source [src]="selectedMessage.mediaURL" type="video/mp4" />
                        </video>
                    </ng-container>
                    <ng-container *ngIf="selectedMessage.mediaType === 'multiple'">
                        <div id="mediaCarousel" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div *ngFor="let media of mediaObjects; let i = index" class="carousel-item {{ i === currentSlideIndex ? 'active' : '' }}">
                                    <img *ngIf="media.type === 'image'" class="d-block w-100" [src]="media.downloadUrl" alt="">
                                    <video *ngIf="media.type === 'video'" class="d-block w-100" [src]="media.downloadUrl" controls autoplay muted playsinline>
                                    </video>
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#mediaCarousel" data-bs-slide="prev" (click)="changeSlide('prev')">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="d-none">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#mediaCarousel" data-bs-slide="next" (click)="changeSlide('next')">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="d-none">Next</span>
                            </button>
                        </div>
                    </ng-container>
                    <mat-card-content>
                        <ng-container *ngIf="selectedMessage.subject">
                            <p class="h5">{{selectedMessage.subject}}</p>
                        </ng-container>
                        <p class="messageBody">{{selectedMessage.message}}</p>
                    </mat-card-content>
                    <mat-card-actions>
                        <ng-container *ngTemplateOutlet="showDefaultReaction ? defaultFavorite : customFavorite;
                            context: { $implicit: selectedMessage }"></ng-container>
                        <ng-container *ngIf="isCoach">
                            <button mat-icon-button color="warn" aria-label="Delete message" (click)="deleteMessage()">
                                Delete
                            </button>
                        </ng-container>
                    </mat-card-actions>
                </mat-card>
            </ng-container>
        </div>
        <div *ngIf="commentsWithAuthorInfo.length > 0" class="d-flex flex-column">
            <ng-container *ngFor="let com of commentsWithAuthorInfo">
                <div class="d-flex justify-content-center">
                    <div class="col-lg-10 col-md-8">
                        <mat-card class="px-md-2 mat-elevation-z0">
                            <mat-card-header>
                                <img mat-card-avatar [src]="com.authorInfo.profilePictureUrl"/>
                                <mat-card-title>
                                    <p class="messageBody comment">
                                        <strong> {{com.authorInfo.name}} {{com.authorInfo.lastName}} </strong> {{com.comment}}
                                    </p>
                                </mat-card-title>
                                <mat-card-subtitle>
                                    {{com.dateTime.toMillis() | date:'medium'}}
                                </mat-card-subtitle>
                            </mat-card-header>
                            <!--<mat-card-content>
                                <ng-container *ngIf="selectedMessage.subject">
                                    <p class="h5">{{selectedMessage.subject}}</p>
                                </ng-container>
                                <p class="messageBody">{{selectedMessage.message}}</p>
                            </mat-card-content>-->
                        </mat-card>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </ng-container>
        </div>
        <!--form to comment-->
        <div class="d-flex justify-content-center" *ngIf="showComments">
            <form [formGroup]="commentsForm" class="col-lg-10 col-md-8 normal-form py-2">
                <div class="controles-container">
                    <div class="row">
                        <mat-form-field class="col" color="accent">
                            <mat-label  i18n="@@media-thread-body-label">Add a comment</mat-label>
                            <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8"
                            formControlName="commentBody"></textarea>
                            <mat-error  i18n="@@media-thread-body-error">Please fill this field</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="button-row px-3" mat-dialog-actions>
                    <button mat-raised-button color="accent" type="submit" [disabled]="commentsForm.invalid" (click)="onPostComment()" class="py-2">
                        Post
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #defaultFavorite let-msg>
    <button
        *ngIf="isMessageLiked(msg.reactions)"
        class="like"
        mat-icon-button
        color="warn"
        aria-label="Liked"
        (click)="changeLikeStatus(msg)"
    >
        <mat-icon>favorite</mat-icon>
    </button>
    <button
        *ngIf="!isMessageLiked(msg.reactions)"
        class="like"
        mat-icon-button
        color="warn"
        aria-label="Like"
        (click)="changeLikeStatus(msg)"
    >
        <mat-icon>favorite_border</mat-icon>
    </button>
    <span>{{ getLikeQty(msg.reactions) }}</span>
</ng-template>

<ng-template #customFavorite let-msg>
    <button
        *ngIf="isMessageLiked(msg.reactions)"
        class="like"
        mat-icon-button
        color="warn"
        aria-label="Liked"
        (click)="changeLikeStatus(msg)"
    >
        <img src="assets/images/reaction_surf_selected.png"
        />
    </button>
    <button
        *ngIf="!isMessageLiked(msg.reactions)"
        class="like"
        mat-icon-button
        color="warn"
        aria-label="Like"
        (click)="changeLikeStatus(msg)"
    >
    <img src="assets/images/reaction_surf_outline.png"
    />
    </button>
    <span>{{ getLikeQty(msg.reactions) }}</span>
</ng-template>
