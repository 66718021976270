import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import bootstrap from 'bootstrap';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HeaderComponent } from 'src/app/common-ui/components/header/header.component';
import { CommonUiModule } from 'src/app/common-ui/common-ui.module';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { CreateMessageComponent } from './create-message/create-message.component';
import { ViewMessagesComponent } from './view-messages/view-messages.component';
import { StoryViewerComponent } from './story-viewer/story-viewer.component';
import { StoryLaunchpadProfileComponent } from './story-launchpad-profile/story-launchpad-profile.component';



@NgModule({
  declarations: [
    CreateMessageComponent,
    ViewMessagesComponent,
    StoryViewerComponent,
    StoryLaunchpadProfileComponent
  ],
  imports: [
    CommonModule,
    CommonUiModule,
    MatOptionModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    // HeaderComponent,
  ],
  exports: [
    CreateMessageComponent,
    ViewMessagesComponent,
    StoryViewerComponent,
    StoryLaunchpadProfileComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MessagesModule { }
