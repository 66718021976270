import { Component, OnInit, Inject, Input, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserInfo } from 'src/app/models/userInfo';
import { Player } from 'src/app/models/player';

@Component({
  selector: 'app-story-launchpad-profile',
  templateUrl: './story-launchpad-profile.component.html',
  styleUrls: ['./story-launchpad-profile.component.css']
})
export class StoryLaunchpadProfileComponent /*implements OnInit*/ {
  @Input() playerId: string;
  @Input() player: Player;
  @Input() userInfo: UserInfo;
  @Input() isCoach: boolean;
  @Input() hasStories: boolean;

  constructor(
    private router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  /*
  ngOnInit(): void {
    // console.log(this.hasStories);
  }*/

  goToStories(): void{
    if(this.hasStories) {
      this.router.navigate(['/stories'], {
        queryParams: {
          playerId: this.playerId
        }
      });
    }
  }

  uploadStory(): void{
    console.log('add story');
    this.router.navigate(['/upload-media'], {
      queryParams: {
        playerId: this.playerId,
        teamId: this.player.teamId,
        uploadedBy: this.userInfo.name + ' ' + this.userInfo.lastName,
        isCoach: this.isCoach,
        story: true
      }
    });
  }

  getBorderStyle():string {
    if(this.hasStories){
      return 'new-story'
    } else {
      return 'add-story';
    }
  }

  get journeyIconRoute(): string {
    return environment.versions.journeyIcon;
  }

}
