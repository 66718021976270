<app-header></app-header>
<div class="container main-div">
    <div class="dashboardHeader row">
        <div class="dashboardInfo col">
            <h1 class="dashboardTitle">{{ title }}</h1>
        </div>
    </div>
<!-- <mat-toolbar color="accent">
    <span>{{ title }}</span>
    <span class="spacer"></span>
    <button
        class="btn-dialog-close"
        mat-stroked-button
        (click)="onClose()"
        tabIndex="-1"
    >
    <mat-icon>clear</mat-icon>
    </button>
</mat-toolbar> -->
<mat-tab-group (selectedTabChange)="onTabChange()">
    <mat-tab label="Upload file" i18n-label="@@create-drill-tab-upload-title">
        <form [formGroup]="fileDrillForm" class="normal-form py-4">
            <mat-grid-list cols="1">
                    <div class="controles-container">
                        <div class="row">
                        <mat-form-field color="accent" class="col-md-6">
                            <input formControlName="fileDrillName" matInput placeholder="Drill name"
                            i18n-placeholder="@@create-drill-upload-drill-name-placeholder"/>
                            <mat-error i18n="@@create-drill-upload-drill-name-error">This is a mandatory field</mat-error>
                        </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field color="accent" class="col-md-4">
                                <mat-select formControlName="fileDrillCategory" placeholder="Drill category"
                                i18n-placeholder="@@create-drill-upload-drill-category-placeholder">
                                    <ng-container *ngFor="let cat of categories">
                                        <mat-option [value]="cat.catValue">
                                            {{ cat.catViewValue }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col" color="accent">
                                <mat-label i18n="@@create-drill-upload-instructions-label">Drill instructions (optional)</mat-label>
                                <textarea matInput cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8"
                                formControlName="fileDrillText" placeholder="Drill instructions (optional)"
                                i18n-placeholder="@@create-drill-upload-instructions-placeholder"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="dropzoneSmall my-1" appDropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)" [class.hovering]="isHovering">
                            <mat-icon font>folder</mat-icon>
                            <h2 i18n="@@create-drill-upload-header">Drag and drop the media here</h2>
                            <label for="filesInput" class="custom-file-upload">
                                <i class="fa fa-cloud-upload"></i> <span i18n="@@create-drill-upload-label">Select file</span>
                            </label>
                            <p class="mx-5 mt-2 text-center"
                            i18n="@@create-drill-upload-video-note">
                                Note: when uploading a video, the recommended filetype is mp4. The .MOV files can have issues when playing in android devices.
                            </p>
                            <input
                                #filesInput
                                id="filesInput"
                                class="custom-file-input"
                                type="file"
                                (change)="cambioArchivo($event)"
                            />
                            </div>
                    </div>
            </mat-grid-list>
            <div class="row"  *ngIf="PreSubmitFiles.length>0">
                <div  class="col mx-0 pt-2">
                    <h3 i18n="@@create-drill-upload-selected-files-header">Selected files</h3>
                </div>
            </div>
            <div class="row" *ngFor="let preSubmitFile of PreSubmitFiles">
                <div  class="col mx-0">
                    <p>{{ preSubmitFile.name }}</p>
                </div>
            </div>
            <div class="button-row my-4">
                <button mat-raised-button color="accent"
                type="submit" [disabled]="fileDrillForm.invalid"
                i18n="@@create-drill-upload-add-button"
                (click)="onSubmitFile()" class="py-2">
                    Add
                </button>
            </div>
            <div class="row">
                <div *ngFor="let file of files" class="col">
                    <h2 i18n="@@create-drill-upload-progress-files-header">Upload progress</h2>
                    <app-upload-task
                        [file]="file"
                        [playerId]="entityId"
                        [teamId]="entityId"
                        [uploadedBy]="entityName"
                        [type]="'drill'"
                        (result)="finalizeSubmitFile($event)"
                    ></app-upload-task>
                </div>
            </div>
        </form>
    </mat-tab>
    <mat-tab label="Add a link" i18n-label="@@create-drill-tab-link-title">
        <form [formGroup]="linkDrillForm" class="normal-form py-4">
            <mat-grid-list cols="1">
                    <div class="controles-container">
                        <div class="row">
                            <mat-form-field color="accent" class="col-md-6">
                                <input formControlName="linkDrillName" matInput placeholder="Drill name"
                                i18n-placeholder="@@create-drill-link-drill-name-placeholder"/>
                                <mat-error i18n="@@create-drill-add-link-name-error">This is a mandatory field</mat-error>
                            </mat-form-field>
                        </div>
                        <mat-form-field color="accent" floatLabel="always">
                            <mat-label i18n="@@create-drill-link-link-label">Drill link</mat-label>
                            <input formControlName="linkDrillLink" matInput placeholder="https://example.com"  type="url"/>
                            <mat-error i18n="@@create-drill-add-link-link-error">This is a mandatory field. The link should begin with http:// or https://</mat-error>
                        </mat-form-field>
                        <div class="row">
                        <mat-form-field color="accent" class="col-md-4" >
                            <mat-select formControlName="linkDrillCategory" placeholder="Drill Category"
                            i18n-placeholder="@@create-drill-link-category-placeholder">
                                <ng-container *ngFor="let cat of categories">
                                    <mat-option [value]="cat.catValue">
                                        {{ cat.catViewValue }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col" color="accent">
                                <mat-label i18n="@@create-drill-link-instructions-label">Drill instructions (optional)</mat-label>
                                <textarea matInput cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8"
                                formControlName="linkDrillText" placeholder="Drill instructions (optional)"
                                i18n-placeholder="@@create-drill-link-instructions-placeholder"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
            </mat-grid-list>
            <div class="button-row" mat-dialog-actions>
                <button mat-raised-button color="accent" type="submit"
                [disabled]="linkDrillForm.invalid"
                i18n="@@create-drill-link-add-button"
                (click)="onSubmitLink()" class="py-2">
                    Add
                </button>
            </div>
        </form>
    </mat-tab>
    <mat-tab label="Only instructions" i18n-label="@@create-drill-tab-instructions-title">
        <form [formGroup]="instructionsDrillForm" class="normal-form py-4">
            <mat-grid-list cols="1">
                    <div class="controles-container">
                        <div class="row">
                            <mat-form-field color="accent" class="col-md-6">
                                <input formControlName="instructionsDrillName" matInput placeholder="Drill name"
                                i18n-placeholder="@@create-drill-instructions-name-placeholder"/>
                                <mat-error i18n="@@create-drill-instructions-name-error">This is a mandatory field</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row">
                        <mat-form-field color="accent" class="col-md-4" >
                            <mat-select formControlName="instructionsDrillCategory" placeholder="Drill category"
                            i18n-placeholder="@@create-drill-instructions-category-placeholder">
                                <ng-container *ngFor="let cat of categories">
                                    <mat-option [value]="cat.catValue">
                                        {{ cat.catViewValue }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field class="col" color="accent">
                                <mat-label i18n="@@create-drill-instructions-instructions-label">Drill instructions</mat-label>
                                <textarea matInput cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8"
                                formControlName="instructionsDrillText" placeholder="Drill instructions"
                                i18n-placeholder="@@create-drill-instructions-text-placeholder"></textarea>
                                <mat-error i18n="@@create-drill-instructions-instructions-error">This is a mandatory field</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
            </mat-grid-list>
            <div class="button-row" mat-dialog-actions>
                <button mat-raised-button color="accent"
                type="submit" [disabled]="instructionsDrillForm.invalid"
                i18n="@@create-drill-instructions-add-button"
                (click)="onSubmitInstructions()" class="py-2">
                    Add
                </button>
            </div>
        </form>
    </mat-tab>
    <mat-tab *ngIf="isTeam" label="Copy from another team" i18n-label="@@create-drill-tab-copy-title">
        <mat-form-field appearance="fill" class="mt-5">
            <mat-label i18n="@@create-drill-copy-select-team-label">1 - Select team</mat-label>
            <mat-select>
                <mat-option (onSelectionChange)="teamSelected($event)" value="none"
                i18n="@@create-drill-copy-select-team-placeholder">Select a team</mat-option>
                <mat-option (onSelectionChange)="teamSelected($event)" *ngFor="let userTeam of userTeams" [value]="userTeam.teamId">
                    {{userTeam.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="!isteamSelected" class="row my-4">
            <span i18n="@@create-drill-copy-select-team-error">A team must be selected to copy a drill from.</span>
        </div>
        <div *ngIf="isteamSelected && drillList.length > 0">
            <div class="row">
                <span i18n="@@create-drill-copy-select-drill-label">2- Select the drill to copy</span>
            </div>
            <div class="row">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    matSortActive="drillName"
                    matSortDirection="asc"
                    class="mat-elevation-z2 col-m-5 mt-2 mb-4"
                >
                <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox
                                color="accent"
                                (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()"
                            >
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                            color="accent"
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="drillName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span i18n="@@create-drill-copy-drill-table-name-header">Name</span>
                        </th>
                        <td mat-cell *matCellDef="let drill">{{ drill.drillName }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </div>
        <div *ngIf="isteamSelected && drillList.length === 0" class="row">
            <div class="col-m-6 my-4">
                <span i18n="@@create-drill-copy-drill-team-nodrills-error">This team has no drills available to copy.</span>
            </div>
        </div>
        <div class="button-row my-2 " mat-dialog-actions>
            <button mat-raised-button color="accent" type="submit"
            i18n="@@create-drill-copy-button"
            [disabled]="selection.selected.length === 0" class="py-2 ml-4" (click)="onCopyDrills()">
                Copy selected
            </button>
        </div>
    </mat-tab>
</mat-tab-group>
</div>
