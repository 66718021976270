<nav class="navbar navbar-dark bg-dark">
    <a class="navbar-brand" href="#">
        <img [src]="navLogo" width="30" height="30" class="d-inline-block align-top" alt="">
    </a>
</nav>

<div class="container">
  <form #form="ngForm" class="normal-form py-4">
    <div class="row">
      <div>
        <h2 i18n="@@create-user-title">Create your user</h2>
      </div>
    </div>
    <mat-grid-list cols="1">
      <div class="controles-container">
        <div class="row">
          <mat-form-field color="primary" class="col-md-6 col-12">
            <input i18n-placeholder="@@email-title" matInput placeholder="Email" [(ngModel)]="email" name="email" type="email" required/>
            <mat-error i18n="@@required-title">This field is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field color="primary" class="col-md-6 col-12">
            <input i18n-placeholder="@@password-title" matInput placeholder="Password" [(ngModel)]="password" name="password" [type]="hidePassword ? 'password' : 'text'" required>
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error i18n="@@required-title">This field is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field color="primary" class="col-md-6 col-12">
            <input i18n-placeholder="@@confirm-password-title" matInput placeholder="Confirm Password" [(ngModel)]="confirmPassword" name="confirmPassword" [type]="hideConfirmPassword ? 'password' : 'text'" required>
            <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error i18n="@@required-title">This field is required.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-grid-list>
    <div class="button-row" mat-dialog-actions>
      <button i18n="@@sign-up-button-title" mat-raised-button color="accent"
        class="py-2" (click)="authService.signUp(email.trim(), password, confirmPassword)"
        [disabled]="form.invalid">
        Sign Up
      </button>
    </div>
  </form>
  <mat-spinner [style.display]="false ? 'block' : 'none'"></mat-spinner>
</div>


