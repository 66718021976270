import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Team } from '../models/team';
import { MatDialog } from '@angular/material/dialog';
import { TeamDialogFormComponent } from '../teams-screen/team-dialog-form/team-dialog-form.component';
import { FirebaseService } from '../shared/firebase.service';
import { NotificationService } from '../shared/notification.service';


@Component({
  selector: 'app-dashboard-actions-panel',
  templateUrl: './dashboard-actions-panel.component.html',
  styleUrls: ['./dashboard-actions-panel.component.css']
})
export class DashboardActionsPanelComponent implements OnInit {
  @Input() entityId: string;
  @Input() editable: boolean;
  @Input() type: string;
  @Input() isCoach: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() result = new EventEmitter<any>();

  private team: Team;
  private teamProfilePictureUrl: string;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private firebase: FirebaseService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    if (this.type === 'team') {
      this.firebase.getTeam(this.entityId).subscribe(data => {
        this.team = data;
        this.firebase
            .getTeamProfilePicture(this.team.teamLogo)
            .subscribe(url => {
              this.teamProfilePictureUrl = url;
        });
      });
    }
  }

  goToMembers() {
    this.router.navigate(['/team-user-list'], {
      queryParams: {
        id: this.entityId,
        editable: this.editable
      }
    });
  }

  onEvaluate() {
    this.router.navigate(['/evaluation', this.entityId]);
  }

  onEdit() {
    const dialogRef = this.dialog.open(TeamDialogFormComponent, {
      width: '40%',
      disableClose: true,
      autoFocus: true,
      data: {callingFrom: 'teamDashboard', teamProfilePictureUrl: this.teamProfilePictureUrl, ...this.team, entityId: this.entityId} }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.invitationCode === '') { result.invitationCode = null; }
        if (result.teamColor === '') { result.teamColor = '#FD2A5C'; }
        if (result.teamLogo === '') { result.teamLogo = 'teams/logos/default.png'; }
        if (result.teamVideoCover === '') { result.teamVideoCover = 'teams/videoCovers/defaultVideoCover.jpg'; }
        if (!result.skillListId || result.skillListId === '') { result.skillListId = 'default'; }
        if (result.invitationCode) {
            this.firebase.editTeamInvitationCode(
              this.entityId, result.name, result.year, result.userId, result.skillListId, result.invitationCode,
              result.teamColor, result.teamLogo, result.premium, result.teamVideoCover, result.membershipMonthCutOffDay)
            .then(
                r => {
                  if (r.status) {
                    this.notificationService.success('Equipo editado');
                  } else {
                    console.error(r.message);
                    this.notificationService.warn('El equipo no pudo ser editado');
                  }
                })
            .finally(() => {
              this.result.emit( {
                teamEdited: true
              });
            });
        } else {
          this.firebase.editTeam(this.entityId, result.name, result.year, result.userId, result.skillListId,
                                        result.invitationCode, result.teamColor, result.teamLogo,
                                        result.premium, result.teamVideoCover, result.membershipMonthCutOffDay)
          .then(
            r => {
              if (r.status) {
                this.notificationService.success('Team edited');
              } else {
                console.error(r.message);
                this.notificationService.error('Team could not be edited');
              }
            })
          .finally(() => {
              this.result.emit( {
              teamEdited: true
            });
          });
        }
      }
    });
  }

}
