<app-header></app-header>
<div class="container main-div">
  <div class="example-loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <ng-container *ngTemplateOutlet="dashboardHeader"></ng-container>
  <ng-template #envScript>
    <div class="col-md-4 col-6 px-0 my-1">
      <img
        src="../../assets/images/laCanteraScript.svg"
        class="img-fluid"/>
    </div>
  </ng-template>
  <ng-template #dashboardHeader>
    <div class="row d-md-none mt-2" *ngIf="!showStats && envTeamName==='PuraVida'">
      <ng-container *ngTemplateOutlet="envScript"></ng-container>
    </div>
    <div class="row dashboardHeader">
      <div
        *ngIf="profilePictureUrl"
        class="my-md-auto col-md-3 mr-md-auto pb-md-auto text-center"
      >
        <img
          [src]="profilePictureUrl"
          class="profile-pic-player-dashboard"
        />
      </div>
      <div class="col-md-9 dashboardInfo mt-3 my-md-auto col-12">
        <div class="row">
          <div class="col-12">
            <ng-container  *ngIf="showStats">
              <p class="dashboardName" i18n="@@player-dashboard-header-title">Athlete stats</p>
            </ng-container>
            <div class="row d-none d-md-block mt-2" *ngIf="!showStats && envTeamName==='PuraVida'">
              <ng-container *ngTemplateOutlet="envScript"></ng-container>
            </div>
            <h1 class="dashboardTitle">{{ playerName }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
            <span class="headerLabel" i18n="@@player-dashboard-header-laterality">Strong side: </span>
            <span class="headerValue">{{ strongFoot }}</span>
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2">
            <span class="headerLabel" i18n="@@player-dashboard-header-position">Position: </span>
            <span class="headerValue">{{ position }}</span>
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2" *ngIf="birthDate">
            <span class="headerLabel" i18n="@@player-dashboard-header-birthdate">Birthdate: </span>
            <span class="headerValue">{{ birthDate }}</span>
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2" *ngIf="showStats">
            <span class="statisticLabel" i18n="@@player-dashboard-header-evaluations">Training sessions: </span>
            <span class="statisticValue"> {{ totalEvaluations }} </span>
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2" *ngIf="showStats">
            <span class="statisticLabel" i18n="@@player-dashboard-header-absences">Abscences: </span>
            <span class="statisticValue"> {{ totalAbsences }} </span>
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2"  *ngIf="playerClub">
            <span class="headerLabel" i18n="@@player-dashboard-header-club">Club: </span>
            <span class="headerValue">{{ playerClub }}</span>
          </div>
          <div class="col-md-4 col-xs-12 pr-md-4 pr-3 mb-2"  *ngIf="playerLevel">
            <span class="headerLabel" i18n="@@player-dashboard-header-level">Level / Team: </span>
            <span class="headerValue">{{ playerLevel }}</span>
          </div>
          <div class="col-md-5 col-xs-12 pr-md-4 pr-3 mb-2" *ngIf="inviteCode">
            <span class="statisticLabel" i18n="@@player-dashboard-header-code">Profile Code: </span>
            <span class="statisticValue"> {{ inviteCode }} </span>
            <button mat-icon-button ngxClipboard (cbOnSuccess)="copiedCode($event)" [cbContent]="inviteCode"
            aria-label="copy athlete code to clipboard"
            i18n-aria-label="@@player-dashboard-header-code-button-aria-label">
              <mat-icon aria-hidden="true" class="sm-icon-button align-self-center">content_copy</mat-icon>
            </button>
          </div>
        </div>
        <ng-container  *ngIf="showStories">
          <app-story-launchpad-profile [player]="player" [playerId]="playerId" [hasStories]="hasStories"
          [userInfo]="userInfo" [isCoach]="isCoach"></app-story-launchpad-profile>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div class="actions" *ngIf="showStats">
    <app-dashboard-actions-panel [entityId]="playerId" [type]="'player'" [editable]="false" [isCoach]="isCoach" (result)="update($event)">
    </app-dashboard-actions-panel>
  </div>
  <div class="w-100">
    <mat-tab-group mat-align-tabs="center">
      <ng-container *ngIf="showStats">
        <mat-tab label="Stats">
          <ng-container *ngTemplateOutlet="playerMetrics"></ng-container>
          <ng-container *ngTemplateOutlet="playerStats"></ng-container>
          <ng-container *ngTemplateOutlet="playerDates"></ng-container>
        </mat-tab>
      </ng-container>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="material-symbols-outlined tab-icon-size ml-1">grid_on</span>
          <span class="d-none d-lg-block">Posts</span>
        </ng-template>
        <ng-container *ngTemplateOutlet="playerMessages"></ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="material-symbols-outlined tab-icon-size ml-1">whatshot</span>
          <span class="d-none d-lg-block">Player Update</span>
        </ng-template>
        <ng-container *ngTemplateOutlet="playerLearning"></ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="material-symbols-outlined tab-icon-size ml-1">video_library</span>
          <span class="d-none d-lg-block">Media</span>
        </ng-template>
        <ng-container *ngTemplateOutlet="playerVids"></ng-container>
        <ng-container *ngTemplateOutlet="playerPics"></ng-container>
      </mat-tab>
      <!--<mat-tab>
        <ng-template mat-tab-label>
          <span class="material-symbols-outlined tab-icon-size ml-1">photo_library</span>
          <span class="d-none d-lg-block">Photos</span>
        </ng-template>
        <ng-container *ngTemplateOutlet="playerPics"></ng-container>
      </mat-tab>-->
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="material-symbols-outlined tab-icon-size ml-1">exercise</span>
          <span class="d-none d-lg-block">Homework</span>
        </ng-template>
        <ng-container *ngTemplateOutlet="playerLearning"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="add-container fixed-bottom">
    <button mat-fab color="accent" class="fab-dashboard-button" aria-label="Add new item" (click)="openBottomSheet()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

<ng-template #playerStats>
  <div class="dashboardCharts">
    <h2 i18n="@@player-dashboard-stats-title">Stats</h2>
    <div class="d-flex flex-wrap flex-md-row justify-content-md-around">
      <div
        class="col-md-4 col-lg-4 dashboardThird my-2 py-3"
        *ngIf="validOverall"
      >
        <h3 class="lightText text-center"
        i18n="@@player-dashboard-charts-overall-title">Overall Stats</h3>
        <app-category-dashboard
          [behavioral]="overallScoreBehavioral"
          [physical]="overallScorePhysical"
          [technical]="overallScoreTechnical"
          [tactical]="overallScoreTactical"
        ></app-category-dashboard>
        <div>
          <mat-accordion>
            <mat-expansion-panel class="dashboardThird lightText">
              <mat-expansion-panel-header class="dark-expansion-header">
                <mat-panel-title
                  class="lightText accordion-chart-title"
                  i18n="@@player-dashboard-charts-overall-explainer-title">
                  What does this mean?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p i18n="@@player-dashboard-charts-overall-explainer-body">
                The chart shows a breakdown of the player's performance by category through time.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div
        class="col-md-4 col-lg-4 dashboardThird my-2 py-3"
        *ngIf="validOverall && validEvolution"
      >
        <h3 class="lightText text-center"
        i18n="@@player-dashboard-charts-comparison-title">Before and after</h3>
        <app-player-polar-chart
          [overallScores]="overallScores"
          [initialScores]="initialScores"
        ></app-player-polar-chart>
        <div>
          <mat-accordion>
            <mat-expansion-panel class="dashboardThird lightText">
              <mat-expansion-panel-header class="dark-expansion-header">
                <mat-panel-title 
                  class="lightText accordion-chart-title"
                  i18n="@@player-dashboard-charts-before-after-explainer-title">
                  What does this mean?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p i18n="@@player-dashboard-charts-before-after-explainer-body">
                The chart shows a comparison between the first evaluation ever and the accumulated progress the athlete has shown.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div
        class="col-md-4 col-lg-4 dashboardThird my-2 py-3"
        *ngIf="validEvolution"
      >
        <h3 class="lightText text-center"
        i18n="@@player-dashboard-charts-evolutions-title">Evolution</h3>
        <app-evolution-dashboard
          [evolutionValues]="evolutionValues"
          [evolutionDates]="evolutionDates"
        ></app-evolution-dashboard>
        <div>
          <mat-accordion>
            <mat-expansion-panel class="dashboardThird lightText">
              <mat-expansion-panel-header class="dark-expansion-header">
                <mat-panel-title 
                  class="lightText accordion-chart-title"
                  i18n="@@player-dashboard-charts-evolutions-explainer-title">
                  What does this mean?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p i18n="@@player-dashboard-charts-evolutions-explainer-body">
                The chart shows the overall score received by a player for every scored training.
                A positive value means that the overall performance for the date was good.
                A negative value means that more improvement areas were recorded (not necessarily the performance was bad).
                The scale may differ to the other charts because its an independent chart.
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div *ngIf="!validStrengths && !validImprovements && !validEvolution" class="col-12">
        <p class="text-center" i18n="@@player-dashboard-stats-empty">No stats yet</p>
      </div>
    </div>
  </div>
  <div class="dashboardSkillCategoryDetails" *ngIf="validStrengths && validImprovements">
    <h2 i18n="@@player-dashboard-skill-category-title">Skills</h2>
    <div class="row">
      <div class="col-sm-6 col-lg-3 skillCatDetail">
        <app-skill-category-details
          [category]="'Físicas'"
          [playerId]="playerId"
          [detailType]="'player'"
          [isCoach]="isCoach"
        >
        </app-skill-category-details>
      </div>
      <div class="col-sm-6 col-lg-3 skillCatDetail">
        <app-skill-category-details
          [category]="'Mentalidad'"
          [playerId]="playerId"
          [detailType]="'player'"
          [isCoach]="isCoach"
        >
        </app-skill-category-details>
      </div>
      <div class="col-sm-6 col-lg-3 skillCatDetail">
        <app-skill-category-details
          [category]="'Táctica'"
          [playerId]="playerId"
          [detailType]="'player'"
          [isCoach]="isCoach"
        >
        </app-skill-category-details>
      </div>
      <div class="col-sm-6 col-lg-3">
        <app-skill-category-details
          [category]="'Técnica'"
          [playerId]="playerId"
          [detailType]="'player'"
          [isCoach]="isCoach"
        >
        </app-skill-category-details>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <mat-accordion>
          <mat-expansion-panel class="">
            <mat-expansion-panel-header>
              <mat-panel-title i18n="@@player-dashboard-skill-category-explainer-title">
                What does this mean?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p i18n="@@player-dashboard-skill-category-explainer-body">
              The numbers shown next to each skill are the times the skill has been evaluated (TE) and the average percentage (AVG).
              The percentage is calculated based on the average of scores and the qty of times the skill has been scored.
              Use these scores to determine which aspects of the game to use for advantage or which ones should be the focus for improvement.
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #playerDates>
  <div class="row dashboardDatesDetail"  *ngIf="totalAbsences > 0 || totalEvaluations > 0">
    <div class="col-md-6" *ngIf="totalAbsences > 0">
      <h2 i18n="@@player-dashboard-absence-dates-title">Absence Details</h2>
      <div class="table-responsive">
        <table class="table table-borderless table-striped table-sm">
          <tbody>
            <ng-container *ngFor="let absence of absencesList">
              <tr>
                <td>
                  {{
                    absence.toLocaleString(locale, {
                      year: "numeric",
                      month: "long",
                      day: "numeric"
                    })
                  }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-6" *ngIf="totalEvaluations > 0">
      <h2 i18n="@@player-dashboard-eval-dates-title">Training Dates</h2>
      <div class="table-responsive">
        <table class="table table-borderless table-striped table-sm">
          <tbody>
            <ng-container *ngFor="let evalDate of evaluationDates">
              <tr>
                <td>
                  <a href="/evaluation/{{playerId}}/{{getEvalDateLink(evalDate)}}"
                    class="mat-row-link">
                    {{
                      evalDate.toLocaleString(locale, {
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                      })
                    }}
                  </a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

  <ng-template #playerVids>
    <!--<div class="dashboardPhotoGalleryHeader">
      <div class="d-flex justify-content-between">
        <h2 i18n="@@player-dashboard-videos-title">Videos</h2>
        <div class="col-auto"> -->
          <!--<button mat-raised-button class="upload-button"
              color="accent" (click)="goToUploadFiles()"
              i18n="@@player-dashboard-videos-add-button"
            >Add video</button>-->
        <!-- </div>
      </div>
    </div> -->
    <div width="100%" class="dashboardSection">
      <div class="dashboardPhotoGallery">
        <ng-container *ngFor="let vid of vidAlbum; let i = index">
          <div>
            <video controls>
              <source src="{{ vid.src }}" type="video/mp4" />
            </video>
            <div class="d-flex mx-2 mb-2">
              <p class="my-auto mr-auto"><small>Uploaded by {{vid.uploadedBy}} <br />
                {{vid.uploadDate.toMillis() | date:'medium'}}
              </small></p>
              <button type="button" (click)="deleteVideo(i)" class="btn btn-sm btn-outline-dark">
                <mat-icon class="small-btn-icon" font>delete_outline</mat-icon>
                <span i18n="@@player-dashboard-videos-delete-button">Delete</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="vidAlbum.length !== playersMediaVidsMetadata.length" align="center">
        <button class="show-more-button" (click)="showMoreVids()">
          <mat-icon font>more_horiz</mat-icon>
        </button>
      </div>
      <div *ngIf="vidAlbum.length===0">
        <p class="text-center" i18n="@@player-dashboard-videos-empty">No videos yet, feel free to add your best!</p>
      </div>
    </div>
  </ng-template>
  <ng-template #playerPics>
    <!-- <div class="dashboardPhotoGalleryHeader">
      <div class="d-flex justify-content-between">
        <h2 i18n="@@player-dashboard-pictures-title">Photos</h2>
      --><!--<button
          mat-raised-button
          color="accent"
          class="upload-button"
          (click)="goToUploadFiles()"
          i18n="@@player-dashboard-pictures-add-button"
        >Add photo</button>-->
      <!-- </div>
    </div> -->
    <div width="100%" class="dashboardSection">
      <div class="dashboardPhotoGallery">
        <ng-container
          *ngFor="let pic of picAlbum; let i = index"
        >
          <div class="picContainer">
            <img
              class="galleryPic"
              src="{{ pic.src }}"
            />
            <div class="middle-image-container">
              <div class="see-image-label mb-3" (click)="openPicture(i)"
              i18n="@@player-dashboard-pictures-view-picture">View</div>
              <div class="see-image-label" (click)="deletePicture(i)"
              i18n="@@player-dashboard-pictures-delete-picture">Delete</div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="picAlbum.length !== playersMediaPicsMetadata.length" align="center">
        <button class="show-more-button" (click)="showMorePics()">
          <mat-icon font>more_horiz</mat-icon>
        </button>
      </div>
      <div *ngIf="picAlbum.length===0">
        <p class="text-center" i18n="@@player-dashboard-pictures-empty">No pictures yet, feel free to add your best!</p>
      </div>
    </div>
  </ng-template>

</div>
<ng-template #messageTiles>
  <div class="dashboardCharts" *ngIf="player"></div>

</ng-template>
<ng-template #playerMessages>
  <div class="dashboardCharts" *ngIf="player">
    <!-- <div class="d-flex justify-content-between flex-wrap">
      <h2 i18n="@@player-dashboard-messages-title">Messages</h2>
      <div class="col-auto">
        <button mat-raised-button class="upload-button mr-3"
          color="accent" (click)="addPlayerDebrief()"
          i18n="@@player-dashboard-debrief-add-button"
        >Add debrief</button>
        <button mat-raised-button class="upload-button"
          color="accent" (click)="addPlayerMessage()"
          i18n="@@player-dashboard-messages-add-button"
        >Add message</button>
      </div>
    </div> -->
    <app-view-messages [type]="'player'" [audience]="getMessageAudience()" [entityId]="playerId" [mode]="'tiles'"></app-view-messages>
  </div>
</ng-template>
<ng-template #playerLearning>
  <div class="dashboardSkillCategoryDetails">
    <!-- <div class="d-flex justify-content-between">
      <h2 class="text-wrap"
      i18n="@@player-dashboard-training-title">Player updates and other documents</h2>
      <div class="col" *ngIf="isCoach">
        <button type="button" (click)="onNewDrill()" class="btn btn btn-outline-dark mb-1"
        i18n="@@player-dashboard-training-add-button">
          Add
        </button>
      </div>
    </div> -->
    <div class="row" *ngIf="!isLoadingResults">
      <div class="col skillCatDetail">
        <app-drill-category-list  [playerId]="playerId" [teamId]="teamId"
        [isTeamList]=false
        [editable]="isCoach">
        </app-drill-category-list>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #playerMetrics>
  <div class="dashboardCharts">
    <h2 i18n="@@player-dashboard-metrics-title">Performance metrics</h2>
    <app-metrics-list
      [playerId]="playerId"
    ></app-metrics-list>
  </div>
</ng-template>
<ng-template let-bottomSheetRef="bottomSheetRef" #templateBottomSheet>
  <mat-action-list>
    <button mat-list-item class="upload-button" (click)="addPlayerMessage()" i18n="@@player-dashboard-messages-add-button">
      <span class="material-symbols-outlined mr-3">add</span>
      Add post
    </button>
    <button mat-list-item (click)="addPlayerDebrief()" i18n="@@player-dashboard-debrief-add-button">
      <span class="material-symbols-outlined mr-3">note_add</span>
      Add debrief
    </button>
    <button mat-list-item (click)="onNewDrill()" i18n="@@player-dashboard-training-add-button">
      <span class="material-symbols-outlined mr-3">whatshot</span>
      Add player update
    </button>
    <button mat-list-item (click)="onNewDrill()" i18n="@@player-dashboard-training-add-button">
      <span class="material-symbols-outlined mr-3">exercise</span>
      Add homework
    </button>
  </mat-action-list>
</ng-template>