import { Component, OnInit, Input, LOCALE_ID, Inject } from '@angular/core';
import { ChartOptions, ChartConfiguration, ChartType, ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-player-polar-chart',
  templateUrl: './player-polar-chart.component.html',
  styleUrls: ['./player-polar-chart.component.css']
})
export class PlayerPolarChartComponent implements OnInit {
  @Input() initialScores: number[];
  @Input() overallScores: number[];

  private initialScorelineColor = '';
  private accumulatedScorelineColor = '';

  private enRadarChartLabels: string[] = [ 'TE', 'TA', 'PH', 'BE'];
  private esRadarChartLabels: string[] = [ 'TE', 'TA', 'FI', 'ME'];

  public radarChartType: ChartType = 'radar';
  public radarChartOptions: ChartOptions = {
    responsive: true,
    scale: {
      display: true,
      angleLines: {
        display: true,
        color: '#FFFFFF'
      },
      gridLines: {
        display: true,
        color: '#8d8d8d',
      },
      ticks: {
        display: false,
        stepSize: 33,
        showLabelBackdrop: false,

      },
      pointLabels: {
        fontColor: '#fbfbfb',
      }
    },
    legend: {
      display: true,
      position: 'top',
      labels: {
        fontColor: '#fbfbfb',
      }
    }
  };
  public radarChartLabels: string[] = [ 'TE', 'TA', 'PH', 'BE'];
  public radarChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Initial',
      fill: false,
      pointHoverRadius: 0
    },
    {
      data: [],
      label: 'Improvement',
      fill: false,
      pointHoverRadius: 0
    }
  ];

  constructor(@Inject(LOCALE_ID) public locale: string) { }

  ngOnInit(): void {
    this.setLocalizedMessages();
    this.setInitialData();
    this.setAccumulatedData();
    this.setScaleProperties();
  }

  setLocalizedMessages(): void {
    if (this.locale.includes('es')) {
      this.radarChartLabels = this.esRadarChartLabels;
      this.radarChartData[0].label = 'Inicial';
      this.radarChartData[1].label = 'Mejora'
    }
  }

  setInitialData(): void {
    this.radarChartData[0].data = this.initialScores;
  }

  setAccumulatedData(): void {
    const accumulatedTechnical = this.overallScores[0];
    const accumulatedTactical = this.overallScores[1];
    const accumulatedPhysical = this.overallScores[2];
    const accumulatedBehavioral = this.overallScores[3];
    this.radarChartData[1].data = [
      accumulatedTechnical,
      accumulatedTactical,
      accumulatedPhysical,
      accumulatedBehavioral
    ];
  }

  setScaleProperties(): void {
    const overallMax = Math.max(...this.overallScores);
    const initialMax = Math.max(...this.initialScores);
    const scaleMax = Math.max(overallMax, initialMax);
    const tickStep = scaleMax / 3;
    this.radarChartOptions.scale.ticks.stepSize = tickStep;
    this.radarChartOptions.scale.ticks.max = overallMax;
  }
}
