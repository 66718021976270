import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent  {
  public email: string;
  public password: string;

  constructor(
    public authService: AuthService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['']);
    }

    /* this.matIconRegistry.addSvgIcon(
      'cantera_logo_full',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/images/canteraLogo_full_black.svg')
    ); */
  }

  

  goToSignUp() {
    this.router.navigate(['sign-up']);
  }

  goToResetPassword() {
    this.router.navigate(['reset-password']);
  }

  get navLogo() {
    return environment.versions.navLogo;
  }

  get fullLogo() {
    return environment.versions.fullLogo;
  }
}
