import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialog,


  MatDialogConfig
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { Player } from '../models/player';
import { PlayerDialogFormComponent } from '../player-dialog-form/player-dialog-form.component';
import { FirebaseService } from '../shared/firebase.service';
import { NotificationService } from '../shared/notification.service';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.css'],
})
export class PlayersComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public displayedColumns: string[] = [
    'select',
    'firstName',
    'lastName',
    'teamId',
    'playerId',
    'userId',
    'state',
    'position',
    'strongFoot',
    'birthDay',
    'birthMonth',
    'birthYear',
    'invitationCode',
    'profilePicturePath',
    'actions',
  ];
  public dataSource = new MatTableDataSource();
  private initialSelection = [];
  private allowMultiSelect = true;
  private selection = new SelectionModel<any>(
    this.allowMultiSelect,
    this.initialSelection
  );

  public isLoadingResults = true;

  private playerList: Player[];

  public title = 'Players';

  private firstNameFilter = new FormControl();
  private lastNameFilter = new FormControl();
  private teamFilter = new FormControl();
  private playerIdFilter = new FormControl();
  private userIdFilter = new FormControl();
  private positionFilter = new FormControl();
  private strongFootFilter = new FormControl();
  private birthDayFilter = new FormControl();
  private birthMonthFilter = new FormControl();
  private birthYearFilter = new FormControl();
  private filteredValues = {
    firstName: '',
    lastName: '',
    teamId: '',
    playerId: '',
    userId: '',
    position: '',
    strongFoot: '',
    birthDay: '',
    birthMonth: '',
    birthYear: '',
  };
  private playerBirthDay: string;
  private playerBirthMonth: string;
  private playerBirthYear: string;

  constructor(
    private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.firebase.getPlayers().subscribe((data) => {
      this.playerList = [];
      data.forEach((p) => {
        this.playerList.push(p as Player);
      });
      this.dataSource = new MatTableDataSource(this.playerList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.selection = new SelectionModel<Player>(
        this.allowMultiSelect,
        this.initialSelection
      );

      this.firstNameFilter.valueChanges.subscribe((firstNameFilterValue) => {
        this.filteredValues['firstName'] = firstNameFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.lastNameFilter.valueChanges.subscribe((lastNameFilterValue) => {
        this.filteredValues['lastName'] = lastNameFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.teamFilter.valueChanges.subscribe((teamFilterValue) => {
        this.filteredValues['teamId'] = teamFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.playerIdFilter.valueChanges.subscribe((playerIdFilterValue) => {
        this.filteredValues['playerId'] = playerIdFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.playerIdFilter.valueChanges.subscribe((userIdFilterValue) => {
        this.filteredValues['userId'] = userIdFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.positionFilter.valueChanges.subscribe((positionFilterValue) => {
        this.filteredValues['position'] = positionFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.strongFootFilter.valueChanges.subscribe((strongFootFilterValue) => {
        this.filteredValues['strongFoot'] = strongFootFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.birthDayFilter.valueChanges.subscribe((birthDayFilterValue) => {
        this.filteredValues['birthDay'] = birthDayFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.birthMonthFilter.valueChanges.subscribe((birthMonthFilterValue) => {
        this.filteredValues['birthMonth'] = birthMonthFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.birthYearFilter.valueChanges.subscribe((birthYearFilterValue) => {
        this.filteredValues['birthYear'] = birthYearFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });

      this.setupFilter();
      this.isLoadingResults = false;
    });
  }

  setupFilter() {
    this.dataSource.filterPredicate = (d: Player, filter: string) => {
      const searchString = JSON.parse(filter);
      if (!d.firstName) {
        d.firstName = '';
      }
      if (!d.lastName) {
        d.lastName = '';
      }
      if (!d.teamId) {
        d.teamId = '';
      }
      if (!d.playerId) {
        d.playerId = '';
      }
      if (!d.userId) {
        d.userId = '';
      }
      if (!d.position) {
        d.position = '';
      }
      if (!d.strongFoot) {
        d.strongFoot = '';
      }
      if (!d.birthDay) {
        d.birthDay = '';
      }
      if (!d.birthMonth) {
        d.birthMonth = '';
      }
      if (!d.birthYear) {
        d.birthYear = '';
      }
      return (
        d.firstName
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.firstName.toLowerCase()) !== -1 &&
        d.lastName
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.lastName.toLowerCase()) !== -1 &&
        d.teamId
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.teamId.toLowerCase()) !== -1 &&
        d.playerId
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.playerId.toLowerCase()) !== -1 &&
        d.userId
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.userId.toLowerCase()) !== -1 &&
        d.position
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.position.toLowerCase()) !== -1 &&
        d.strongFoot
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.strongFoot.toLowerCase()) !== -1 &&
        d.birthDay
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.birthDay.toLowerCase()) !== -1 &&
        d.birthMonth
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.birthMonth.toLowerCase()) !== -1 &&
        d.birthYear
          .toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.birthYear.toLowerCase()) !== -1
      );
    };
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Player): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} player ${
      row.firstName + ' ' + row.lastName
    }`;
  }

  /**Function called when the add player button is clicked */
  onNewPlayer(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '45%';
    const dialogRef = this.dialog.open(PlayerDialogFormComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.invitationCode === '') {
          result.invitationCode = null;
        }
        result.position = result.position.slice(0, -2);
        result.strongFoot = this.translateStrongFoot(result.strongFoot);
        if (result.birthDate != null) {
          this.parseDate(result.birthDate).then((res) => {
            this.firebase.addPlayer(
              result.teamId,
              result.firstName,
              result.lastName,
              result.userId,
              result.position,
              result.strongFoot,
              this.playerBirthDay,
              this.playerBirthMonth,
              this.playerBirthYear,
              result.invitationCode,
              result.profilePicturePath,
              result.club,
              result.level
            );
          });
        } else {
          this.firebase.addPlayerNoDate(
            result.teamId,
            result.firstName,
            result.lastName,
            result.userId,
            result.position,
            result.strongFoot,
            result.invitationCode,
            result.profilePicturePath
          );
        }
        this.notification.success('Player added');
      }
    });
  }

  async parseDate(date: Date): Promise<boolean> {
    this.playerBirthDay = await date.getDate().toString();
    this.playerBirthMonth = await (date.getMonth() + 1).toString();
    this.playerBirthYear = await date.getFullYear().toString();
    return true;
  }

  translateStrongFoot(strongFoot: string) {
    if (strongFoot === '0') {
      return '-';
    }
    if (strongFoot === '1') {
      return 'Right';
    }
    if (strongFoot === '2') {
      return 'Left';
    } else {
      return 'Both';
    }
  }

  onEditPlayer(row) {
    const dialogRef = this.dialog.open(PlayerDialogFormComponent, {
      width: '45%',
      disableClose: true,
      autoFocus: true,
      data: row,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        result.position = result.position.slice(0, -2);
        result.strongFoot = this.translateStrongFoot(result.strongFoot);
        if (result.birthDate != null) {
          this.parseDate(result.birthDate).then((res) => {
            this.firebase.editPlayer(
              row.playerId,
              result.firstName,
              result.lastName,
              result.teamId,
              result.userId,
              result.position,
              result.strongFoot,
              this.playerBirthDay,
              this.playerBirthMonth,
              this.playerBirthYear,
              result.invitationCode,
              result.profilePicturePath,
              result.club,
              result.level
            );
          });
        } else {
          this.firebase.editPlayerNoDate(
            row.playerId,
            result.firstName,
            result.lastName,
            result.teamId,
            result.userId,
            result.position,
            result.strongFoot,
            result.invitationCode,
            result.profilePicturePath,
            result.club,
            result.level
          );
        }
        this.notification.success('Player edited');
      }
    });
  }

  onDeletePlayer(row) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '30%',
      disableClose: true,
      autoFocus: true,
      data: { action: 'delete player', object: row },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.firebase.deletePlayer(row.playerId);
        this.notification.success('Player deleted');
      }
    });
  }
}
