import { Component, ElementRef, OnInit, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMessage } from 'src/app/models/app-message';
import { MediaMetadata } from 'src/app/models/mediaMetadata';
// import { UserInfo } from 'src/app/models/userInfo';
import { Player } from 'src/app/models/player';
import { Team } from 'src/app/models/team';
import { User } from 'src/app/models/user';
import { FirebaseService } from '../../shared/firebase.service';
import { NotificationService } from '../../shared/notification.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from '../../auth/auth.service';
import { RoleService } from '../../shared/role.service';
import { flatMap, map } from 'rxjs/operators';
// import { HeaderComponent } from 'src/app/header/header.component';
// import { MatLabel, MatOption, MatSelect, MatFormField, MatError } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { Supported } from 'src/app/models/supported';
import { UserInfo } from 'src/app/models/userInfo';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-create-message',
  templateUrl: './create-message.component.html',
  styleUrls: ['./create-message.component.css']
})

export class CreateMessageComponent implements OnInit {
  @ViewChild('filesInput')
  filesInputVariable: ElementRef;

  public isTeam: boolean;
  entityName: string;
  private entityId: string;

  private isLoadingResults = true;

  private player: Player;
  private team: Team;
  myUserId: string;

  URL_REGEXP = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
  isHovering: boolean;
  PreSubmitFiles: File[] = [];
  files: File[] = [];
  UploadedFiles: MediaMetadata[] = [];

  uploadedMediaType?: string;
  uploadedMediaURL?: string;
  uploadedMediaPath?: string;
  uploadedMediaFormat?: string;
  public isDebrief = false;
  messageContent = 'Development recommendation';
  public shownFormGroup: FormGroup;

  public messagesForm = new FormGroup({
    action: new FormControl('new'),
    type: new FormControl('message'),
    messageBody: new FormControl('', Validators.required),
    messageSubject: new FormControl('',  Validators.required)
  });

  public messageDebriefForm = new FormGroup({
    action: new FormControl('new'),
    type: new FormControl('message'),
    whatDidWeWorkToday: new FormControl('',  Validators.required),
    whatWentWellToday: new FormControl(''),
    whatToImprove: new FormControl('')
  });

  playerDashboardLink = '/player-dashboard/';
  teamDashboardLink = '/team-dashboard/';

  constructor(private firebase: FirebaseService
    , private notification: NotificationService
    , private authService: AuthService
    , private router: Router
    , private route: ActivatedRoute
    , private functions: AngularFireFunctions
    , private roleService: RoleService
    , @Inject(LOCALE_ID) public locale: string) { }

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      params['type'] === 'team' ? this.isTeam = true : this.isTeam = false;

      this.entityId = params['id'];
      this.entityName = params['name'];
      this.isDebrief = params['debrief'] ? params['debrief'] : false;
      // console.log(this.isDebrief);
      if(this.isDebrief) {
        this.shownFormGroup = this.messageDebriefForm;
        this.messageContent = 'Debrief';
      } else {
        this.shownFormGroup = this.messagesForm;
      }
      // console.log(this.entityId);
      // console.log(this.entityName);

      if (!this.entityId) {
        this.notification.error(this.getErrorMessage());
        this.router.navigate(['/home']);
      }

      this.myUserId = this.authService.getUser.uid;
    });
    this.isLoadingResults = true;

    this.playerDashboardLink+=this.entityId;
    this.teamDashboardLink+=this.entityId;

    const role = await this.roleService.getRole(this.authService.user.uid);
    if (role === 'Coach') {
      this.setCoachOptions();
    }

    if (!this.isTeam) {
      this.firebase.getPlayer(this.entityId)
        .pipe(
          flatMap((playerData: Player) => {
            // console.log(playerData);
            return this.firebase.getTeam(playerData.teamId)
            .pipe(map(playersTeam => ({ playerData, playersTeam})));
          })
        )
      .subscribe( ({ playerData, playersTeam }) => {
        // console.log(playerData);
        // console.log(playersTeam);
        this.player = playerData as Player;
        this.team = playersTeam as Team;
        this.isLoadingResults = false;
      });
    }
  }

  initializeFormGroup() {
    this.messagesForm.setValue({
      messageSubject: '',
      messageBody: '',
      action: 'new',
      type: new FormControl('file'),
    });
  }

  async finalizeUploadFile(result: any) {
    // console.log('ps:' + this.PreSubmitFiles.length + ', fi: ' + this.files.length);

    if(this.files.length === 1){
      this.uploadedMediaPath = result.path;
      this.uploadedMediaURL = result.downloadURL;
      this.uploadedMediaFormat = result.format;
      this.uploadedMediaType = result.type;

      if (this.isTeam) {
        await this.sendTeamMessage(true);
      } else {
        await this.sendPlayerMessage(true);
      }
      this.navigateAfterSubmit();
    } else if(this.files.length > 1){
      this.UploadedFiles.push({
        isStory: false,
        downloadUrl: result.downloadURL,
        fileName: result.fileName,
        playerId: this.entityId,
        storagePath: result.path,
        teamId: this.player.teamId,
        type: result.type,
        uploadDate: new Date(),
        uploadedByName: this.myUserId,
        format: result.format,
        firebaseFormat: result.format,
        id: 'new'
      });
      // console.log('uf: ' + this.UploadedFiles.length);
      
      if(this.UploadedFiles.length === this.files.length){
        // console.log('trying to upload', this.UploadedFiles);
        await this.sendPlayerMessageWithMultipleMedia();
        this.navigateAfterSubmit();
      }
    }

  }

  async onSubmitMessage() {

    if(this.PreSubmitFiles.length < 1){
      if (this.isTeam) {
        await this.sendTeamMessage(false);
      } else {
        await this.sendPlayerMessage(false);
      }
      this.navigateAfterSubmit();
    } else {
      for (let i = 0; i < this.PreSubmitFiles.length; i++) {
        this.files.push(this.PreSubmitFiles[i]);
      }
    }
    // this.navigateAfterSubmit();
  }

  async sendTeamMessage(hasMedia: boolean) {
    const body: string = this.messagesForm.get('messageBody').value;
    const newMessage: AppMessage = {
      userId: this.myUserId,
      groupId: this.entityId,
      message: body,
      audience: 'all',
      dateTime: new Date(),
      subject: this.messagesForm.get('messageSubject').value,
      mediaPath: this.uploadedMediaPath,
      mediaURL: this.uploadedMediaURL,
      mediaFormat: this.uploadedMediaFormat,
      mediaType: this.uploadedMediaType,
    };
    this.firebase.addMessage(newMessage, hasMedia);

    const  callableFunction = this.functions.httpsCallable('sendMessageNotificationToTeam');
    const response = callableFunction({
      teamId: this.entityId,
      teamName: this.entityName,
      message: body,
    });

    this.notification.success(this.getSuccessMessage());

  }
  getMessageBody() : string {
    let body: string;
    if(this.isDebrief) {
      body = 'What did we work on today? \n';
      body += this.messageDebriefForm.get('whatDidWeWorkToday').value + '\n\n';
      body += 'What went well about today’s session? \n';
      body += this.messageDebriefForm.get('whatWentWellToday').value + '\n\n';
      body += 'What is worth improving about today’s session? \n';
      body += this.messageDebriefForm.get('whatToImprove').value;
    } else {
      body = this.messagesForm.get('messageBody').value;
    }
    // console.log(body);
    return body;

  }

  async sendPlayerMessage(hasMedia: boolean) {
    const body: string = this.getMessageBody();
    const subj = this.isDebrief ? 'Debrief' : this.messagesForm.get('messageSubject').value;
    const msjDate = new Date();
    const newMessage: AppMessage = {
      userId: this.myUserId,
      groupId: this.player.teamId,
      message: body,
      audience: this.player.userId ? this.player.userId : this.entityId,
      dateTime: msjDate ,
      subject: subj,
      mediaPath: this.uploadedMediaPath,
      mediaURL: this.uploadedMediaURL,
      mediaFormat: this.uploadedMediaFormat,
      mediaType: this.uploadedMediaType,
    };
    this.firebase.addMessage(newMessage, hasMedia);

    this.notifyPlayer();
    this.notifySupporters();

    const  callableFunction = this.functions.httpsCallable('sendMessageNotificationToPlayer');
    const response = callableFunction({
      isUser: this.player.userId ? true : false,
      id: this.player.userId ? this.player.userId : this.entityId,
      teamName: this.team.name,
      message: newMessage.message,
      });
    this.notification.success(this.getSuccessMessage());
  }

  async sendPlayerMessageWithMultipleMedia() {
    // console.log('send multiple message');
    const body: string = this.getMessageBody();
    const subj = this.isDebrief ? 'Debrief' : this.messagesForm.get('messageSubject').value;
    const msjDate = new Date();
    const newMessage: AppMessage = {
      userId: this.myUserId,
      groupId: this.player.teamId,
      message: body,
      audience: this.player.userId ? this.player.userId : this.entityId,
      dateTime: msjDate ,
      subject: subj,
      mediaType: 'multiple',
      mediaFormat: 'multiple',
      mediaPath: '',
      mediaURL: '',
      additionalMedia: this.UploadedFiles
    };
    this.firebase.addMessageMultipleMedia(newMessage);

    this.notifyPlayer();
    this.notifySupporters();

    const  callableFunction = this.functions.httpsCallable('sendMessageNotificationToPlayer');
    const response = callableFunction({
      isUser: this.player.userId ? true : false,
      id: this.player.userId ? this.player.userId : this.entityId,
      teamName: this.team.name,
      message: newMessage.message,
      });
    this.notification.success(this.getSuccessMessage());
  }

  async notifyPlayer() {
    const pname = this.player.firstName;
    const pid = this.entityId;
    const emailTemplate= 'newmessage';
    const link = environment.baseURL + 'player-dashboard/' + pid;
    const preferences =  environment.baseURL + 'profile';
    const bccRecipients = environment.versions.bccEmailNotifications;

    //get userinfo for the player
    this.firebase
      .getUserInfoById(this.player.userId)
      .pipe(take(1))
      .subscribe(userInfoQuery => {
        const playerUserInfo = userInfoQuery[0] as UserInfo;
        // console.log(playerUserInfo);
        if(!(playerUserInfo.emailSubscription === false) && playerUserInfo.email) {
          // console.log('email sent');
          this.firebase.addNewMessageMail(emailTemplate, playerUserInfo.email
          , link, pname, preferences, bccRecipients);
        }
      });
    //verify if the player is subscribed
    //send email
    //this.firebase.addNewMessageMail(emailTemplate, 'mwachowski@gmail.com'
    //, link, pname, preferences);


  }
  
  async notifySupporters() {
    const pname = this.player.firstName;
    const pid = this.entityId;
    const emailTemplate= 'newmessage';
    const link = environment.baseURL + 'player-dashboard/' + pid;
    const preferences =  environment.baseURL + 'profile';

    //get users that support the player
    //get userinfo for the supporters
    //verify each supporter is subscribed to notifications
    //send email
    this.firebase.getSupporters(this.player.userId ? this.player.userId : this.entityId).
      subscribe(supporters => {
        supporters.forEach((supported: Supported) => {
          this.firebase
            .getUserInfoById(supported.userId)
            .pipe(take(1))
            .subscribe(users => {
              const userInfo = users[0] as UserInfo;
              if(!(userInfo.emailSubscription === false) && userInfo.email) {
                this.firebase.addNewMessageMail(emailTemplate, userInfo.email
                , link, pname, preferences);
              }
            });
        });
      });


  }

  getSuccessMessage(): string {
    return (this.locale.includes('en') ? 'Message sent' : 'Mensaje enviado');
  }

  getErrorMessage(): string {
    return (this.locale.includes('en') ? 'Can\'t send message' : 'El mensaje no puede enviarse');
  }

  navigateAfterSubmit() {
    if (this.isTeam) {
      //this.router.navigate(['/team-dashboard', this.entityId]);
      this.router.navigateByUrl(this.teamDashboardLink);
    } else {
      /*this.router.navigate(['/player-dashboard', this.entityId], {
        queryParams: { 'isCoach': 'true'}
      });*/
      this.router.navigateByUrl(this.playerDashboardLink);
    }
  }

  /* * * * * * * **
  * uploader code *
  * * * * * * * **/
    toggleHover(event: boolean) {
      this.isHovering = event;
    }

    onDrop(files: FileList) {
      for (let i = 0; i < 1; i++) {
        this.PreSubmitFiles.push(files.item(i));
      }
    }

    cambioArchivo(event: any) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.PreSubmitFiles.push(event.target.files.item(i));
      }
      this.resetInput();
    }

    resetInput() {
      this.filesInputVariable.nativeElement.value = '';
    }

    setCoachOptions() {
      //this.isCoach = true;
      this.playerDashboardLink += '?isCoach=true';
      this.teamDashboardLink += '?isCoach=true';
    }

}
