<div class="d-flex flex-row">
    <div class="d-flex flex-column align-items-center mr-4">
        <div class="story-thumb-ext-circle" [ngClass]="getBorderStyle()"> <!-- este puede ser el circulo exterior-->
            <div class="story-thumb-inner-circle new-story-inner-circle" role="button" (click)="goToStories()">
                <img [src]="journeyIconRoute" alt="" width="60" height="60"/>
            </div>
        </div>
        <span class="higlight-name mt-2">My Journey</span>
    </div>
    <div class="d-flex flex-column align-items-center">
        <div class="story-thumb-ext-circle add-story">
            <div class="d-flex justify-content-center align-items-center
            story-thumb-inner-circle add-story-inner-circle accent-bg primary-font-color"
            (click)="uploadStory()">
                <span>+</span>
            </div>
        </div>
        <span class="higlight-name mt-2">Add story</span>
    </div>
</div>
