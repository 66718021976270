// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// dev_puravida
/*
export const environment = {
  production: false,
  firebase: {
    // measurementId: 'G-HJXLYWR3YV',
    apiKey: 'AIzaSyBkJCmO7G2Q57ja3Skytf1f3x6Wp66S37g', //
    authDomain: 'pvet-dev-7265b.firebaseapp.com', //
    databaseURL: 'https://pvet-dev-7265.firebaseio.com',
    projectId: 'pvet-dev-7265b', //
    storageBucket: 'pvet-dev-7265b.appspot.com', //
    appId: '1:281079494075:web:c1c96492ed8918e043809e', //
    messagingSenderId: '281079494075', //
  },
  baseURL: 'https://pvet-dev.cantera.app/',
  defaultSkillListId: 'kEkh6rnS6RGuZqtFiQDW',
  defaultVideoCover: 'teams/videoCovers/defaultVideoCover.jpg',
  signUp: {
    enableParentOption: false,
    enableJoinTeamOption: false,
  },
  home: {
    coach: {
      show: 'players',
    },
  },
  teamDashboard: {
    showStats: false,
  },
  playerDashboard: {
    showNegative: false,
    showStats: false,
    showComments: false,
    defaultReaction: false,
    showStories: false,
  },
  versions: {
    navLogo: '../../../../assets/images/puravida_NavLogo.svg',
    fullLogo: '../../../../assets/images/puravida_Logo.svg',
    teamLogo:
      '../../../../assets/images/teamDefaults/puravida_defaultTeamLogo.png',
    teamVideoCover:
      '../../../../assets/images/teamDefaults/puravida_defaultVideoCover.jpg',
    journeyIcon:
      '../../../../assets/images/journey-icon-pv-100.jpg',
    teamColor: '#FD2A5C',
    teamName: 'PuraVida',
    bccEmailNotifications: ['deftersports@gmail.com'],
  },
};
*/
// prod_puravida

export const environment = {
  production: false,
  firebase: {
    measurementId: 'G-3KZLLDN3MM',
    apiKey: 'AIzaSyBKcm7pEa38JWnNQsaP3PnGsYfCDfpvk1w', //
    authDomain: 'puravidaelite-training.firebaseapp.com', //
    databaseURL: 'https://puravidaelite-training.firebaseio.com',
    projectId: 'puravidaelite-training', //
    storageBucket: 'puravidaelite-training.appspot.com', //
    appId: '1:533824658226:web:a6582d915f2967862f152b', //
    messagingSenderId: '533824658226', //
  },
  baseURL: 'https://puravida.cantera.app/',
  defaultSkillListId: 'default',
  defaultVideoCover: 'teams/videoCovers/defaultVideoCover.jpg',
  signUp: {
    enableParentOption: false,
    enableJoinTeamOption: false,
  },
  home: {
    coach: {
      show: 'players',
    },
  },
  teamDashboard: {
    showStats: false,
  },
  playerDashboard: {
    showNegative: false,
    showStats: false,
    showComments: false,
    defaultReaction: false,
    showStories: false,
  },
  versions: {
    navLogo: '../../../../assets/images/puravida_NavLogo.svg',
    fullLogo: '../../../../assets/images/puravida_Logo.svg',
    teamLogo:
      '../../../../assets/images/teamDefaults/puravida_defaultTeamLogo.png',
    teamVideoCover:
      '../../../../assets/images/teamDefaults/puravida_defaultVideoCover.jpg',
    journeyIcon:
      '../../../../assets/images/journey-icon-pv-100.jpg',
    teamColor: '#FD2A5C',
    teamName: 'PuraVida',
    bccEmailNotifications: ['cesarequ27@gmail.com','mark@puravidaelite.org', 'o.cervantes007@gmail.com', 'jemmamerrick6@gmail.com'],
  },
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
