import { Component, Input, LOCALE_ID, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { Skill } from 'src/app/models/skill';
import { Evaluation } from '../../models/evaluation';
import { FirebaseService } from '../../shared/firebase.service';
import { ChartOptions, ChartType, ChartDataSets, ChartPluginsOptions } from 'chart.js';
import { Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-metric-detail',
  templateUrl: './metric-detail.component.html',
  styleUrls: ['./metric-detail.component.css']
})
export class MetricDetailComponent implements  OnChanges {
  @Input() playerId: string;
  @Input() skillCode: string;
  @Input() skill: Skill;
  @Input() localizedSkillName: string;

  metricResults: Evaluation[] = [];
  isLoadingResults = true;
  oldSkillCode: String = '';

  // chart variables
  chartData: number[] = [];
  public lineChartData: ChartDataSets[] = [
    { data: [], label: '', fill: false },
  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales : {
      xAxes: [{
        ticks: {
          fontFamily: '\'Roboto\', sans-serif',

        },
      }],
      yAxes: [{
        ticks: {
          display: true,
          beginAtZero: true,
          fontFamily: '\'Roboto\', sans-serif'
        },
      }]
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
    },
  ];
  public lineChartLegend = false;
  public lineChartType = 'line';
  public lineChartPlugins: ChartPluginsOptions = {};

  constructor(private firebase: FirebaseService
    , @Inject(LOCALE_ID) public locale: string) { }

  

  ngOnChanges(changes: SimpleChanges) {
    if (changes.skillCode && (changes.skillCode.currentValue !== changes.skillCode.previousValue)) {
      this.loadData();
    }

  }

  loadData() {
    this.isLoadingResults = true;
    this.oldSkillCode = this.skillCode;
    this.metricResults = [];
    this.chartData = [];
    this.lineChartLabels = [];
    this.firebase.getPlayerEvaluationsBySkillCode(this.playerId, this.skillCode)
    .subscribe(evaluation => {
      evaluation.forEach(e => {
        // const evaluation = e as Evaluation;
        this.metricResults.push(e as Evaluation);
      });
      this.metricResults.sort(this.compareDates);
      this.metricResults.forEach(e => {
        this.chartData.push(e.skillResult);
        this.lineChartLabels.push(this.getEvaluationDate(e));
      });
      this.lineChartData[0].data = this.chartData;
      this.lineChartData[0].label = this.getChartDataLabel();
      // this.barChartLabels = this.evolutionDates;
      // console.log(this.metricResults);



      this.isLoadingResults = false;
    });
  }

  getMetricName(metricSkill: Skill): string {
    if (metricSkill.enDisplaySkill && metricSkill.esDisplaySkill) {
      return (this.locale.includes('en') ? metricSkill.enDisplaySkill : metricSkill.esDisplaySkill);
      /*if(this.locale.includes('en')) {
        return metricSkill.enDisplaySkill;
      } else {
        return metricSkill.esDisplaySkill;
      }*/
    } else {
      return metricSkill.code;
    }
  }

  getChartDataLabel(): string {
    return (this.locale.includes('en') ? 'Score' : 'Puntaje');
  }

  getEvaluationDate(evaluation: Evaluation): string {
    const eDate = new Date(evaluation.year, evaluation.month - 1, evaluation.day);
    const evalDate = eDate.toLocaleString(this.locale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    return (evalDate ? evalDate : '');
  }

  compareDates(a: any, b: any) {
    const dateA = new Date(a.year, a.month - 1, a.day);
    const dateB = new Date(b.year, b.month - 1, b.day);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

}
