import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { NotificationService } from '../shared/notification.service';

export interface Position {
  posValue: string;
  posViewValue: string;
}

@Component({
  selector: 'app-player-dialog-form',
  templateUrl: './player-dialog-form.component.html',
  styleUrls: ['./player-dialog-form.component.css']
})
export class PlayerDialogFormComponent {
  public title: string;

  positions: Position[] = [
    { posValue: '--0', posViewValue: '-' },
    { posValue: 'Goalkeeper-0', posViewValue: 'Goalkeeper' },
    { posValue: 'Central Back-1', posViewValue: 'Central Back' },
    { posValue: 'RB/LB-2', posViewValue: 'RB / LB' },
    { posValue: 'CMF-3', posViewValue: 'CMF' },
    { posValue: 'Midfielder-4', posViewValue: 'Midfielder' },
    { posValue: 'FW-5', posViewValue: 'FW' }
  ];

  public playerForm = new FormGroup({
    action: new FormControl('new'),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    teamId: new FormControl('', Validators.required),
    userId: new FormControl(''),
    strongFoot: new FormControl('0'),
    position: new FormControl('--0'),
    birthDate: new FormControl(null),
    invitationCode: new FormControl(null),
    profilePicturePath: new FormControl('profilePictures/default.png'),
    club: new FormControl(''),
    level: new FormControl(''),
  });

  public startDate = new Date(2000, 0, 1);
  public maxDate = new Date();

  constructor(
    private notification: NotificationService,
    public dialogRef: MatDialogRef<PlayerDialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.title = 'Edit Player';
      this.populateForm(this.data);
    } else {
      this.title = 'New Player';
    }
  }

  initializeFormGroup() {
    this.playerForm.setValue({
      action: 'new',
      firstName: '',
      lastName: '',
      teamId: '',
      userId: '',
      strongFoot: '0',
      position: '',
      birthDate: null,
      invitationCode: null,
      profilePicturePath: 'profilePictures/default.png',
      club: '',
      level: '',
    });
  }

  onClear() {
    this.playerForm.reset();
    this.initializeFormGroup();
    this.notification.success('Cleared successfully');
  }

  onClose() {
    this.playerForm.reset();
    this.initializeFormGroup();
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.playerForm.value);
  }

  populateForm(player) {
    this.initializeFormGroup();
    if (player.birthDay && player.birthMonth && player.birthYear) {
      this.playerForm.setValue({
        action: 'edit',
        firstName: player.firstName,
        lastName: player.lastName,
        teamId: player.teamId,
        userId: player.userId ? player.userId : null,
        strongFoot: this.checkStrongFoot(player.strongFoot),
        position: this.checkPosition(player.position),
        birthDate: new Date(
          Number(player.birthYear),
          Number(player.birthMonth - 1),
          Number(player.birthDay)
        ),
        invitationCode: player.invitationCode ? player.invitationCode : null,
        profilePicturePath: player.profilePicturePath ? player.profilePicturePath : null,
        club: player.club ? player.club : null,
        level: player.level ? player.level : null
      });
    } else {
      this.playerForm.setValue({
        action: 'edit',
        firstName: player.firstName,
        lastName: player.lastName,
        teamId: player.teamId,
        userId: player.userId ? player.userId : null,
        strongFoot: this.checkStrongFoot(player.strongFoot),
        position: this.checkPosition(player.position),
        birthDate: null,
        invitationCode: player.invitationCode ? player.invitationCode : null,
        profilePicturePath: player.profilePicturePath ? player.profilePicturePath : null,
        club: player.club ? player.club : null,
        level: player.level ? player.level : null
      });
    }
  }

  checkStrongFoot(strongFoot: string) {
    if (strongFoot === '-') {
      return '0';
    }
    if (strongFoot === 'Right') {
      return '1';
    }
    if (strongFoot === 'Left') {
      return '2';
    } else {
      return '3';
    }
  }

  checkPosition(position: string) {
    if (position === '-') {
      return '--0';
    }
    if (position === 'Goalkeeper') {
      return 'Goalkeeper-0';
    }
    if (position === 'Central Back') {
      return 'Central Back-1';
    }
    if (position === 'RB/LB') {
      return 'RB/LB-2';
    }
    if (position === 'CMF') {
      return 'CMF-3';
    }
    if (position === 'Midfielder') {
      return 'Midfielder-4';
    } else {
      return 'FW-5';
    }
  }
}
