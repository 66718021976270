import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { AppMessage } from 'src/app/models/app-message';
import { UserInfo } from 'src/app/models/userInfo';
import { environment } from '../../../environments/environment';
import { FirebaseService } from '../../shared/firebase.service';

@Component({
  selector: 'app-view-messages',
  templateUrl: './view-messages.component.html',
  styleUrls: ['./view-messages.component.css'],
})
export class ViewMessagesComponent implements OnInit {
  @Input() audience: string;
  @Input() groupId?: string;
  @Input() type: string;
  @Input() entityId?: string;
  @Input() mode:string;

  isTeam: boolean;
  isLoadingResults = false;
  receivedMessages: AppMessage[] = [];
  receivedMessagesWithAuthors: AppMessage[] = [];
  receivedMessagesAuthors: string[] = [];
  authorImgs: string[] = [];
  userId: string;

  constructor(
    private firebase: FirebaseService,
    private authService: AuthService,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit() {
    this.type === 'team' ? (this.isTeam = true) : (this.isTeam = false);

    if (this.isTeam) {
      console.log('addTeam messages logic');
    } else {
      this.receivedMessagesWithAuthors = [];
      this.receivedMessagesAuthors = [];
      this.receivedMessages =[];
      // messages for players
      this.firebase
        .getMessagesForPlayer(this.audience)
        //.pipe(take(1))
        .subscribe((msgs: AppMessage[]) => {
          this.isLoadingResults = true;
          if (msgs != null) {
            msgs.forEach((msg) => {
              if (msg.message && msg.dateTime) {
                this.firebase
                  .getUserInfoById(msg.userId)
                  .pipe(take(1))
                  .subscribe((info: any[]) => {
                    const completeMsg = msg;
                    const author = info[0] as UserInfo;
                    // console.log(author);
                    // completeMsg.dateTime = new Date(msg.dateTime.valueOf());
                    completeMsg.authorInfo = author;
                    if (!author.profilePictureUrl) {
                      this.firebase
                        .getProfilePicture(
                          author.profilePicturePath ||
                            'profilePictures/default.png'
                        )
                        .pipe(take(1))
                        .subscribe((pic: string) => {
                          completeMsg.authorInfo.profilePictureUrl = pic;
                          const msgExists = this.receivedMessagesWithAuthors.some(item => item.messageId === completeMsg.messageId);
                          if(!msgExists) {
                            this.receivedMessagesWithAuthors.push(completeMsg);
                            this.receivedMessagesWithAuthors.sort((a, b) => {
                              return a.dateTime > b.dateTime
                                ? -1
                                : a.dateTime < b.dateTime
                                ? 1
                                : 0;
                            });
                          }
                        });
                    } else {
                      const msgExists = this.receivedMessagesWithAuthors.some(item => item.messageId === completeMsg.messageId);
                      if(!msgExists) {
                        this.receivedMessagesWithAuthors.push(completeMsg);
                        this.receivedMessagesWithAuthors.sort((a, b) => {
                          return a.dateTime > b.dateTime
                            ? -1
                            : a.dateTime < b.dateTime
                            ? 1
                            : 0;
                        });
                      }
                    }
                  });
              }
            });
          }

          // const completeMessages$ = from(this.receivedMessagesWithAuthors);
          // completeMessages$.subscribe(())

          /* the following is not executed beacause of concurrency issues*/
          /* this.receivedMessagesWithAuthors.sort((a, b) => {
          return a.dateTime > b.dateTime ? -1 : a.dateTime < b.dateTime ? 1 : 0;
        });*/

          this.isLoadingResults = false;
        });
    }
  }

  getUserEmailLink(email: string): string {
    return 'mailto:' + email + '?subject=Reply to evaluation';
  }

  changeLikeStatus(msg: AppMessage) {
    if (msg.reactions && msg.reactions[this.authService.getUser.uid]) {
      delete msg.reactions[this.authService.getUser.uid];
    } else if (msg.reactions) {
      msg.reactions[this.authService.getUser.uid] = 1;
    } else {
      msg.reactions = {};
      msg.reactions[this.authService.getUser.uid] = 1;
    }
    this.firebase.updateMessageReactions(msg);
  }

  isMessageLiked(reactions: any) {
    return reactions && reactions[this.authService.getUser.uid];
  }

  getLikeQty(reactions: any) {
    let qty = 0;
    if (reactions) {
      Object.keys(reactions).forEach((k) => (qty += reactions[k]));
    }
    return qty > 0 ? qty : '';
  }

  openMediaThread(msg: AppMessage) {
    this.router.navigate(['/media-thread'], {
      queryParams: {
        type: 'message',
        id: msg.messageId,
        player: this.entityId,
      },
    });
  }

  get showComments(): boolean {
    return environment.playerDashboard.showComments;
  }

  get showDefaultReaction(): boolean {
    return environment.playerDashboard.defaultReaction;
  }

  getFontSize() {
    // Adjust base size and scale factor as needed
    const baseSize = 24; // Initial font size (px)
    const scaleFactor = 0.02; // Adjust font size based on screen width

    const screenWidth = window.innerWidth;
    const adjustedSize = baseSize + (screenWidth * scaleFactor);

    return Math.min(adjustedSize, 50); // Set a maximum font size (optional)
  }
}
