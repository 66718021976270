import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent  {
  public email: string;
  public disableResetButton = true;
  public showResetPasswordResult = false;
  public resetPasswordResult = false;

  constructor(
    public authService: AuthService,
    private router: Router,
  ) {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['']);
    }
  }

  

  goToSignUp() {
    this.router.navigate(['sign-up']);
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  get navLogo() {
    return environment.versions.navLogo;
  }

  get fullLogo() {
    return environment.versions.fullLogo;
  }

  onEmailChange(emailInputValue: string): void {
    console.log(emailInputValue);
    if (emailInputValue && emailInputValue !== '') {
      console.log(this.email);
      this.disableResetButton = false;
    }
    if (emailInputValue === '') {
      this.disableResetButton = true;
    }
  }

  async resetPassword() {
    this.showResetPasswordResult = false;
    await this.authService.resetPassword(this.email).then(result => {
      if (result) {
        this.showResetPasswordResult = true;
        this.resetPasswordResult = true;
      } else {
        this.showResetPasswordResult = true;
        this.resetPasswordResult = false;
      }
    }).catch(e => {
      this.showResetPasswordResult = true;
      this.resetPasswordResult = false;
    });
  }

}
